import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { cleanCheckedItems, setAllChecked, setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { removeApolloCacheKeys, replaceTags } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { CORPORATE_CHANNEL, CUSTOMISED_CHANNEL, PRESET_CHANNEL } from "../../Utils/Services/ChannelsUtils";
import { useNavigate as useHistory } from "react-router-dom";

//Helpers
import { updateInfoChannel } from "../../../helpers/constants/channels-queries";
import { Session } from "../../Utils/Session";
import { changeActionValues } from "../../../actions/globalActions";

const UpdateChannels = ({ client }) => {
    const { t } = useTranslation();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const { checkedItems } = useSelector((state) => state.table);
    const history = useHistory();
    const [mutation, setMutation] = useState(`
    mutation {
        createHotspot(name: "test") {
          ok
          error
          id
        }
      }`);

    const dispatch = useDispatch();
    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            toast.error(err.message);
        },
    });

    useEffect(() => {
        let execute = false;
        const name = (executeQuery?.params?.name || "").replace(/"/g, '\\"');

        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            executeQuery.redirectOnSuccessUrl = null;
            execute = true;
            let type;
            switch (executeQuery.action) {
                case "add-channel":
                    switch (actionData.values["add-channel-class"]) {
                        case PRESET_CHANNEL.value:
                            setMutation(`
							mutation {
								createPredefinedTunesBatch(
                                    predefinedTunes:{
									    tvChannelID:${actionData.values["add-channel-preset"][0]}
									    ${
                                            actionData.values["add-channel-preset-tuneinfo"]
                                                ? `tuneInfo: ${JSON.stringify(
                                                      actionData.values["add-channel-preset-tuneinfo"]
                                                  )}`
                                                : ""
                                        }
								    }
                                ){
									error
									ok
                                    id
									__typename
								}
							}
							`);
                            executeQuery.mutationName = "createPredefinedTunesBatch";
                            break;
                        case CUSTOMISED_CHANNEL.value:
                            setMutation(`
							mutation {
								createCustomTunesBatch(customTunes:[{
									name:"${actionData.values["add-channel-name"]}",
									languageRef:"${actionData.values["add-channel-language"]}",
									countryRef:"${actionData.values["add-channel-country"]}",
									isRadio: false
								}]){
									error
									ok
                                    id
									__typename
								}
							}
							`);
                            executeQuery.mutationName = "createCustomTunesBatch";
                            break;
                        default:
                            execute = false;
                            break;
                    }
                    executeQuery.closeModal = true;
                    executeQuery.redirectOnSuccessUrl = `#/services/tv-channels/channel/{{id}}`;
                    break;
                case "delete-channel":
                    setMutation(`
                    mutation {
                        deleteTune(
							ids:[${actionData.itemsAffected[0]}]
                        ) {
							error
							ok
							__typename
                        }
                      }
                    `);
                    executeQuery.mutationName = "deleteTune";
                    executeQuery.closeModal = true;
                    break;
                case "add-corporate-channel-property":
                    if (actionData.values["create-corp-channel-opt"] === "create-global") {
                        setMutation(`
                            mutation{
                                createCorporateTune(
                                corpChannelID: ${
                                    executeQuery?.params?.id
                                        ? executeQuery.params.id
                                        : actionData.values["select-global-corporate-channel"][0]
                                }
                                tuneInfo: "null"
                                ){
                                error
                                id
                                ok
                                }
                            }`);
                        executeQuery.mutationName = "createCorporateTune";
                        executeQuery.redirectOnSuccessUrl = `#/services/tv-channels/channel/{{id}}`;
                    } else {
                        setMutation(`mutation {
                                createCorporateTvChannel(name: "${actionData.values["create-corp-channel-name"]}") {
                                  id
                                  tuneID
                                  ok
                                  error
                                }
                              }
                              `);
                        executeQuery.mutationName = "createCorporateTvChannel";
                        executeQuery.redirectOnSuccessUrl = `#/services/tv-channels/channel/{{tuneID}}`;
                    }
                    break;
                case "add-corporate-channel-without-global-management":
                    setMutation(`mutation {
                                createCorporateTvChannel(name: "${actionData.values["name-corporate-channel"]}") {
                                  id
                                  ok
                                  error
                                }
                              }
                              `);
                    executeQuery.mutationName = "createCorporateTvChannel";
                    break;
                case "batch-delete-channels":
                    setMutation(`
                    mutation {
                        deleteTune(
                            ids:[${checkedItems.join(",")}]
                        ) {
                            error
                            ok
                            __typename
                        }
                        }
                    `);
                    executeQuery.mutationName = "deleteTune";
                    executeQuery.closeModal = true;
                    break;
                case "update-tune":
                    setMutation(`
                    mutation {
                        updateTune(
                            id:${executeQuery.params.id}
                            tuneInfo: ${executeQuery.params.tuneInfo}
                        ) {
                            error
                            ok
                            __typename
                        }
                        }
                    `);
                    executeQuery.mutationName = "updateTune";
                    executeQuery.closeModal = false;
                    break;
                case "update-channel":
                    type = executeQuery.params.type;
                    if (type === CORPORATE_CHANNEL.value) {
                        setMutation(`
                            mutation {
                                updateCorporateTvChannel(
                                    id:${executeQuery.params.id}
                                    ${name ? `name:"${name}"` : ""}
                                ){
                                    error
                                    ok
                                    __typename
                                }
                            }
                        `);
                        executeQuery.mutationName = "updateCorporateTvChannel";
                    } else {
                        setMutation(`
                            mutation {
                                updateCustomTvChannel(
                                    id:${executeQuery.params.id}
                                    ${name ? `name:"${name}"` : ""}
                                    ${
                                        executeQuery.params.countryRef
                                            ? `country:"${executeQuery.params.countryRef}"`
                                            : ""
                                    }
                                    ${
                                        executeQuery.params.languageRef
                                            ? `language:"${executeQuery.params.languageRef}"`
                                            : ""
                                    }
                                    ${
                                        executeQuery.params.isRadio !== undefined && executeQuery.params.isRadio !== ""
                                            ? `isRadio:${executeQuery.params.isRadio}`
                                            : ""
                                    }
                                ){
                                    error
                                    ok
                                    __typename
                                }
                            }
                        `);
                        executeQuery.mutationName = "updateCustomTvChannel";
                    }
                    executeQuery.closeModal = false;
                    break;
                case "add-bulk-channels":
                    history("/services/tv-channels/channels/add-bulk");
                    execute = false;
                    const typeBulkChannelSelected = actionData?.["values"]?.["bulk-channel-selected"];
                    Session.setSessionProp("type-bulk-selected", typeBulkChannelSelected);
                    dispatch(setModalContent(false));
                    dispatch(closeModal());
                    dispatch(setExecuteQuery(null));
                    break;
                case "channel-update-remember-last":
                    setMutation(`
                    mutation {
                        setTVSettings(
                            rememberLastTvChannel:${executeQuery?.params?.value}
                        ) {
                            error
                            ok
                            __typename
                        }
                    }`);
                    executeQuery.mutationName = "setTVSettings";
                    executeQuery.closeModal = false;
                    break;
                case "channel-update-always-display-tv":
                    setMutation(`
                    mutation {
                        updateHighAvailabilityConfig(
                            alwaysDisplayTvChannels:${executeQuery?.params?.value}
                        ) {
                            error
                            ok
                            __typename
                        }
                    }`);

                    executeQuery.mutationName = "updateHighAvailabilityConfig";
                    executeQuery.closeModal = false;
                    break;
                case "update-high-availability-text":
                    if (!actionData.values) {
                        dispatch(closeModal());
                        return;
                    }
                    setMutation(`
                    mutation {
                        updateHighAvailabilityConfig(
                            connectionLost: {${Object.keys(actionData.values.connectionLost).map(
                                (item) =>
                                    `${item}: ${
                                        actionData.values.connectionLost[item]
                                            ? `"${actionData.values.connectionLost[item]
                                                  .replace(/<p><\/p>/g, "")
                                                  .replace(/\n/g, "\\n")
                                                  .replace(/"/g, "'")
                                                  .trim()}"`
                                            : `""`
                                    }`
                            )}}
                            connectionRestored: {${Object.keys(actionData.values.connectionRestored).map(
                                (item) =>
                                    `${item}: ${
                                        actionData.values.connectionRestored[item]
                                            ? `"${actionData.values.connectionRestored[item]
                                                  .replace(/<p><\/p>/g, "")
                                                  .replace(/\n/g, "\\n")
                                                  .replace(/"/g, "'")
                                                  .trim()}"`
                                            : `""`
                                    }`
                            )}}
                        )
                    {
                      error
                      ok
                      msg
                    }
                    }`);

                    executeQuery.mutationName = "updateHighAvailabilityConfig";
                    executeQuery.closeModal = true;
                    break;
                case "update-channel-image":
                    type = actionData.values.type;
                    if (type === CORPORATE_CHANNEL.value) {
                        const mutation = `
                                mutation {
                                    updateCorporateTvChannel(
                                        id:${actionData.values.id}
                                        logo:"${
                                            actionData.values["browser-media-library-items-selected"]
                                                ? actionData.values["browser-media-library-items-selected"][0]
                                                : ``
                                        }"
                                    ){
                                        error
                                        ok
                                        __typename
                                    }
                                }
                            `;

                        setMutation(mutation);
                        executeQuery.mutationName = "updateCorporateTvChannel";
                    } else {
                        const mutation = `
                                mutation {
                                    updateCustomTvChannel(
                                        id:${actionData.values.id}
                                        logo:"${
                                            actionData.values["browser-media-library-items-selected"]
                                                ? actionData.values["browser-media-library-items-selected"][0]
                                                : ``
                                        }"
                                    ){
                                        error
                                        ok
                                        __typename
                                    }
                                }
                            `;
                        setMutation(mutation);
                        executeQuery.mutationName = "updateCustomTvChannel";
                    }
                    executeQuery.closeModal = true;
                    break;

                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && executeQuery && data[executeQuery.mutationName] && data[executeQuery.mutationName].ok === true) {
            toast.success(t("operation-successful"));
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }

            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(setAllChecked(false));
            dispatch(cleanCheckedItems());
            dispatch(showGlobalLoading(false));
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));

            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }

            if (executeQuery.redirectOnSuccessUrl) {
                const regex = "{{(.*?)}}";
                redirect(replaceTags(executeQuery.redirectOnSuccessUrl, regex, data[executeQuery.mutationName]));
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

const redirect = (path) => {
    window.location.href = path;
};

export default withApollo(UpdateChannels);
