import { LIST_MOBILE_APPS } from "components/Section/Customers/allProperties/constants";
import { DEVICE_FUNCTIONS, DEVICE_STATUS } from "./Hotel/Monitor/ListDevicesUtils";
import { CORPORATE_CHANNEL, CUSTOMISED_CHANNEL, PRESET_CHANNEL, TUNE_TYPES } from "./Services/ChannelsUtils";

export const GetAvailableFilters = (sectionName, dataOne, dataTwo, isCorporate, dataThree) => {
    let res;
    const areaCleaning = "area-cleaning";
    switch (sectionName) {
        case "list-languages":
            res = isCorporate
                ? [
                      {
                          name: "projectID",
                          label: "properties",
                          data: dataOne,
                          value: dataOne[0],
                          filterType: "selectWithSearch",
                          adjust: "w-80 whitespace-nowrap overflow-ellipsis ",
                          stringType: true,
                      },
                  ]
                : [];
            break;
        case LIST_MOBILE_APPS:
            res = [
                {
                    name: "isMultiproperty",
                    label: "all",
                    data: ["", "global-apps", "property-apps"],
                    filterType: "select",
                    itemType: "area-capacity",
                    adjust: "w-44",
                    translatable: true,
                    // icons: true,
                },
            ];
            break;
        case "list-cleaning":
            res = [
                {
                    name: "category",
                    label: "category",
                    data: dataOne,
                    filterType: "selectWithSearch",
                    adjust: "w-44",
                    itemType: areaCleaning,
                },
                {
                    name: "lastCleaningStaffId",
                    label: "responsible",
                    data: dataTwo,
                    filterType: "selectWithSearch",
                    adjust: "w-44",
                    itemType: areaCleaning,
                },
            ];
            break;
        case "list-staff":
            res = [
                {
                    name: "roles",
                    label: "accesses",
                    data: ["", "2", "1", "3"],
                    filterType: "select",
                    itemType: "roles",
                    translatable: true,
                },
            ];
            break;
        case "list-capacity":
            res = [
                {
                    name: "category",
                    data: dataOne,
                    filterType: "selectWithSearch",
                    itemType: areaCleaning,
                    adjust: "w-44",
                },
                {
                    name: "capacityRange",
                    data: ["", "low", "medium", "high"],
                    filterType: "select",
                    itemType: "area-capacity",
                    adjust: "w-44",
                    translatable: true,
                    icons: true,
                },
            ];
            break;
        case "booking-category":
            res = [
                {
                    name: "state",
                    data: ["", "0", "1", "2,3"],
                    filterType: "select",
                    itemType: "booking",
                    adjust: "w-44",
                    translatable: true,
                },
            ];
            break;
        case "booking-categories":
            res = [
                {
                    name: "category",
                    data: dataOne,
                    filterType: "selectWithSearch",
                    itemType: "booking",
                    adjust: "w-52",
                    literal: true,
                },
            ];
            break;
        case "areas":
            res = [
                {
                    name: "category",
                    data: dataOne,
                    filterType: "selectWithSearch",
                    itemType: "booking",
                    adjust: "w-52",
                    literal: true,
                },
            ];
            break;
        case "list-customers":
            if (dataTwo) {
                res = [
                    {
                        name: "logins",
                        label: "accesses-text",
                        data: dataOne.access,
                        search: true,
                        filterType: "checkboxDropdown",
                        adjust: "w-52 whitespace-nowrap overflow-ellipsis ",
                        noSelect: true,
                    },
                    {
                        name: "marketingAccepted",
                        label: "communications",
                        data: ["", "accepted", "not-accepted"],
                        filterType: "select",
                        itemType: "boolean",
                        translatable: true,
                    },
                    {
                        name: "emailVerified",
                        label: "verification",
                        data: ["", "verified-user", "unverified"],
                        filterType: "select",
                        itemType: "boolean",
                        translatable: true,
                    },
                    {
                        name: "country",
                        data: dataOne.countries,
                        adjust: "w-44",
                        filterType: "selectWithSearch",
                    },
                ];
            } else {
                res = [
                    {
                        name: "signup",
                        label: "signUp",
                        data: dataOne.signUps,
                        search: true,
                        filterType: "checkboxDropdown",
                        adjust: "w-52 whitespace-nowrap overflow-ellipsis ",
                        noSelect: true,
                    },
                    {
                        name: "termsAccepted",
                        label: "gdpr",
                        data: ["", "accepted", "not-accepted"],
                        filterType: "select",
                        itemType: "boolean",
                        translatable: true,
                    },
                ];
            }
            break;
        case "list-messages-sent":
            res = [
                {
                    label: "type",
                    name: "messages-priority",
                    data: ["", "standard", "important", "priority", "critical"],
                    adjust: "w-44",
                    filterType: "select",
                    translatable: true,
                    convertToType: true,
                },
                {
                    label: "expiration",
                    name: "messages-expiration",
                    data: ["", "expired", "not_expired", "not_defined"],
                    adjust: "w-44",
                    filterType: "select",
                    translatable: true,
                    convertToType: true,
                },
            ];
            break;
        case "list-messages-bin":
        case "list-messages-drafts":
            res = [
                {
                    label: "type",
                    name: "messages-priority",
                    data: ["", "standard", "important", "priority", "critical"],
                    adjust: "w-44",
                    filterType: "select",
                    translatable: true,
                    convertToType: true,
                },
            ];
            break;
        case "list-messages-scheduled":
            res = [
                {
                    label: "type",
                    name: "messages-priority",
                    data: ["", "standard", "important", "priority", "critical"],
                    adjust: "w-44",
                    filterType: "select",
                    translatable: true,
                    convertToType: true,
                },
                {
                    label: "scheduling",
                    name: "messages-scheduling",
                    adjust: "w-44",
                    filterType: "scheduling",
                    translatable: true,
                    convertToType: true,
                },
            ];
            break;
        case "list-guests-currents":
            res = [
                {
                    name: "room",
                    label: "room",
                    data: dataOne,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-40 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                },
                {
                    name: "guestGroup",
                    label: "group",
                    data: dataTwo,
                    search: true,
                    filterType: "checkboxDropdown",
                    noSelect: true,
                },
                {
                    name: "stay-date",
                    data: dataTwo,
                    filterType: "date",
                },
            ];
            break;
        case "list-guests-upcoming-check-ins":
            res = [
                {
                    name: "stay-date",
                    data: dataTwo,
                    filterType: "date",
                },
                {
                    label: "status",
                    name: "state",
                    data: ["", "pending_completion", "pending_validation", "pending_modification", "validated"],
                    adjust: "w-44",
                    filterType: "select",
                    noSelect: true,
                    translatable: true,
                },
            ];
            break;
        case "list-device":
            res = [
                {
                    name: "function",
                    label: "device-function",
                    data: dataOne.deviceFunctions,
                    search: false,
                    sort: true,
                    filterType: "checkboxDropdown",
                    noSelect: true,
                    translatable: true,
                },
                {
                    name: "model",
                    label: "device-model",
                    data: dataOne.deviceModels,
                    search: true,
                    sort: true,
                    filterType: "checkboxDropdown",
                    noSelect: true,
                    translatable: false,
                    stringType: true,
                },
                {
                    name: "floorIDs",
                    label: "floor",
                    data: dataOne.floors,
                    search: true,
                    sort: true,
                    filterType: "checkboxDropdown",
                    noSelect: true,
                    translatable: false,
                    selectedById: true,
                },
                {
                    name: "status",
                    label: "status",
                    data: dataOne.status,
                    search: false,
                    sort: true,
                    filterType: "checkboxDropdown",
                    noSelect: true,
                    translatable: true,
                    stringType: true,
                },
            ];
            break;
        case "list-rooms":
            res = [
                {
                    name: "floorID",
                    label: "floor",
                    data: dataOne.floors,
                    search: true,
                    translatable: true,
                    filterType: "checkboxDropdown",
                    noSelect: true,
                    selectedById: true,
                },
            ];
            if (dataThree) {
                res.push({
                    name: "status",
                    data: ["", "occupied", "empty"],
                    filterType: "select",
                    itemType: "room",
                    translatable: true,
                });
            }
            res.push({
                name: "roomGroupID",
                label: "group",
                data: dataTwo,
                search: true,
                adjust: "w-52 whitespace-nowrap overflow-ellipsis ",
                filterType: "checkboxDropdown",
                translatable: false,
                noSelect: true,
            });
            break;
        case "list-topology-rooms":
            res = [
                /*{
                    name: "wing",
                    itemType: "wing",
                    data: dataOne.wings,
                    adjust: "w-44",
                    search: true,
                    filterType: "selectWithSearch",
                },*/
                {
                    name: "floor",
                    label: "floor",
                    data: dataOne.floors,
                    //adjust: "w-44",
                    search: true,
                    translatable: true,
                    filterType: "checkboxDropdown",
                    noSelect: true,
                    selectedById: true,
                },
                {
                    name: "location",
                    itemType: "location",
                    data: dataOne.locations,
                    adjust: "w-44",
                    search: true,
                    filterType: "selectWithSearch",
                } /*,
                {
                    name: "floor",
                    label: "floor",
                    data: dataOne.floors,
                    search: true,
                    translatable: true,
                    filterType: "selectWithSearch",
                },
                {
                    name: "floor",
                    label: "location",
                    data: dataOne.locations,
                    search: true,
                    translatable: true,
                    filterType: "selectWithSearch",
                },*/,
            ];
            break;
        case "list-users":
            res = [
                {
                    name: "roles",
                    label: "roles",
                    data: dataOne,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-52 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                },
                {
                    name: "property",
                    label: "properties",
                    data: dataTwo,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-52 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                },
                {
                    name: "hasCorporate",
                    data: ["", "corporate-users", "non-corporate-users"],
                    filterType: "select",
                    itemType: "boolean",
                    translatable: true,
                    adjust: "w-52",
                },
            ];
            break;
        case "list-account-users":
            res = [
                {
                    name: "account",
                    label: "account",
                    data: dataOne,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-52 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                },
                {
                    name: "property",
                    label: "properties",
                    data: dataTwo,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-52 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                },
            ];
            break;
        case "list-roles":
            res = [
                {
                    name: "accesses",
                    label: "accesses-text",
                    data: dataOne,
                    search: true,
                    sort: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-52 whitespace-nowrap overflow-ellipsis ",
                    stringType: true,
                    noSelect: true,
                },
                {
                    name: "type",
                    data: ["", "customised", "predefined"],
                    filterType: "select",
                    itemType: "type",
                    translatable: true,
                    adjust: "capitalize lowercase",
                    isEnum: true,
                },
            ];
            break;
        case "list-designs":
            res = [
                {
                    name: "isCorporate",
                    label: "design type",
                    data: [
                        "",
                        {
                            label: "global-designs",
                            value: "corporate",
                        },
                        {
                            label: "property-designs",
                            value: "local",
                        },
                    ],
                    filterType: "select",
                    itemType: "boolean",
                    adjust: "w-44",
                    translatable: true,
                    icons: true,
                },
            ];
            break;
        case "list-templates":
            res = [
                {
                    name: "type",
                    label: "screen-type",
                    translatable: true,
                    data: dataOne,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-52 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                },
            ];
            break;
        case "list-tags":
            res = [
                {
                    name: "target",
                    label: "type",
                    translatable: true,
                    data: dataOne,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-60 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                },
            ];
            break;
        case "list-properties":
            res = [
                {
                    name: "brand",
                    label: "property-groups",
                    translatable: true,
                    data: dataOne,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-60 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                },
            ];
            break;
        case "list-propertyGroups":
            res = [
                {
                    name: "property",
                    label: "property",
                    data: dataOne,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-52 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                },
            ];
            break;
        case "list-alarms-":
        case "list-alarms-active":
        case "list-alarms-expired":
            res = [
                {
                    name: "roomName",
                    label: "room",
                    data: dataOne,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-40 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                    selectedById: false,
                    stringType: true,
                },
                {
                    name: "editedByGuest",
                    label: "edited-by",
                    data: ["", "by guest", "manageUsers"],
                    filterType: "select",
                    itemType: "boolean",
                    icons: false,
                    translatable: true,
                },
            ];
            break;
        case "list-passcodes-":
        case "list-passcodes-active":
        case "list-passcodes-expired":
            res = [
                sectionName === "list-passcodes-active" && {
                    name: "unlimitedDuration",
                    label: "duration",
                    data: ["", "unlimited-duration"],
                    filterType: "select",
                    adjust: "w-52",
                    itemType: "boolean",
                    icons: false,
                    translatable: true,
                },
                {
                    name: "accessProfilesId",
                    label: "access-profile",
                    data: dataOne[0],
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-52 ",
                    noSelect: true,
                },
                sectionName === "list-passcodes-active" && {
                    name: "init",
                    label: "state",
                    data: ["", "started", "not-started"],
                    filterType: "select",
                    adjust: "w-52",
                    itemType: "boolean",
                    icons: false,
                    translatable: true,
                },
            ];
            break;
        case "list-shops":
            res = [
                {
                    name: "state",
                    label: "state",
                    data: ["", "available", "unavailable"],
                    filterType: "select",
                    itemType: "boolean",
                    adjust: "w-44",
                    translatable: true,
                    icons: false,
                },
            ];
            break;
        case "list-products":
            res = [
                // {
                //     name: "categories",
                //     label: "categories",
                //     data: ["", "available", "unavailable"],
                //     filterType: "select",
                //     itemType: "boolean",
                //     adjust: "w-44",
                //     translatable: true,
                //     icons: false,
                // },
                {
                    name: "state",
                    label: "state",
                    data: ["", "available", "unavailable"],
                    filterType: "select",
                    itemType: "boolean",
                    adjust: "w-44",
                    translatable: true,
                    icons: false,
                },
            ];
            break;
        case `list-channels-`:
        case `list-channels-${CORPORATE_CHANNEL.name}`:
        case `list-channels-${PRESET_CHANNEL.name}`:
        case `list-channels-${CUSTOMISED_CHANNEL.name}`:
            res = [
                // {
                //     label: "tune",
                //     name: "tuneType",
                //     data: ["", ...Object.keys(TUNE_TYPES).map((actionKey) => TUNE_TYPES[actionKey].label)],
                //     adjust: "w-44",
                //     filterType: "select",
                //     translatable: true,
                //     convertToType: true,
                // },
                {
                    label: "type",
                    name: "isRadio",
                    data: ["", "tv", "radio"],
                    adjust: "w-44",
                    filterType: "select",
                    translatable: true,
                    convertToType: true,
                    disabled: dataOne && dataOne.folder === CORPORATE_CHANNEL.value,
                },
            ];
            break;
        case "list-predefined-channels":
            res = [
                {
                    label: "type",
                    name: "isRadio",
                    data: ["", "tv", "radio"],
                    adjust: "w-44",
                    filterType: "select",
                    translatable: true,
                    convertToType: true,
                },
                {
                    name: "language",
                    label: "language",
                    data: dataOne.languagesOptions,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-44 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                    stringType: true,
                },
                {
                    name: "country",
                    label: "country",
                    data: dataOne.countriesOptions,
                    search: true,
                    filterType: "checkboxDropdown",
                    adjust: "w-44 whitespace-nowrap overflow-ellipsis ",
                    noSelect: true,
                    stringType: true,
                },
            ];
            break;
        default:
            res = [];
    }
    return res;
};

export default GetAvailableFilters;
