import "../../date";
import { Session } from "./Session";
import { toast } from "react-toastify";
import { joinFormDateTime, isValidDate } from "./Utils";
import { ALARM_RECIPIENTS_TYPE, ALARM_TYPE } from "./Customers/AlarmUtils";
import moment from "moment";
import { ADD_PRODUCT_EXTRA, UPDATE_PRODUCT_EXTRA, UPDATE_PRODUCT_SETTINGS } from "./Integrations/ShopSettingsUtils";
import { CUSTOMISED_CHANNEL, PRESET_CHANNEL } from "./Services/ChannelsUtils";
import { forbiddenWords } from "./ForbiddenWords";

export const validateEmail = (text) => {
    // eslint-disable-next-line
    const mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return text && text.match(mailformat) ? true : false;
};
const validatePhone = (text) => {
    // eslint-disable-next-line
    const phoneformat = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    return text && text.match(phoneformat) ? true : false;
};
export const validateNoEmpty = (text) => {
    if (typeof text === "string") {
        text = text.trim();
    }
    return text && text.length > 0 ? true : false;
};
export const validatePositive = (number) => {
    return parseInt(number) > 0;
};
export const validateNoDecimals = (number) => {
    return number % 1 === 0;
};

const validateWhiteSpaces = (str) => {
    return /\s/.test(str);
};

const validateMacDirection = (mac) => {
    const regexMac = /^([0-9a-fA-F]{2}[:.-]?){5}[0-9a-fA-F]{2}$/gim;
    return regexMac.test(mac);
};

const validateIPaddress = (value) => {
    const regexIP =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return regexIP.test(value);
};

export const validate244IPaddress = (ip) => {
    const regex =
        /^(22[4-9]|23[0-9])\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return regex.test(ip);
};

const validateNonSpecialCharactes = (string) => {
    if (/[^a-zA-Z0-9]/gim.test(string)) {
        return false;
    } else {
        return true;
    }
};

const validateOperationThan = (formula, value1, value2) => {
    let response = true;
    switch (formula) {
        case "<":
            response = value1 < value2;
            break;
        case ">":
            response = value1 > value2;
            break;
        case "=":
            response = value1 === value2;
            break;
        case "<=":
            response = value1 <= value2;
            break;
        case ">=":
            response = value1 >= value2;
            break;
        default:
            break;
    }
    return response;
};

export const validateUrl = (string) => {
    const urlPattern =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm;
    return urlPattern.test(string);
};

export const validateRtp = (string) => {
    const regex =
        /(rt[s]?p:\/\/)([0-9]?[0-9]?[0-9]?)\.([0-9]?[0-9]?[0-9]?)\.([0-9]?[0-9]?[0-9]?)\.([0-9]?[0-9]?[0-9]?):([0-9][0-9][0-9][0-9][0-9]?)$/;
    console.log(regex.test(string));
    return regex.test(string);
};
const validateHasInitial0 = (value) => {
    if (value.length > 1 && parseFloat(value[0]) === 0) {
        return true;
    }
};

const validateDateIsBetween = (datetime, before, after) => {
    const datetime_format = "YYYY-MM-DDTHH:mm:ssZ";
    const time_format = "HH:mm:ss";
    let originalDatetime = moment(datetime, datetime_format);
    let transformed = moment({
        hour: originalDatetime.hour(),
        minute: originalDatetime.minute(),
        second: originalDatetime.second(),
    });
    let beforeTime = moment(before, time_format);
    let afterTime = moment(after, time_format);
    return transformed.isBetween(beforeTime, afterTime, null, "[]");
};

const now = new window.ZDate();
now.setSeconds(0);
now.setMilliseconds(0);

export const dateBeforNow = (date) => {
    const nowDate = new window.ZDate();
    nowDate.setSeconds(0);
    nowDate.setMilliseconds(0);
    if (date < nowDate) {
        return true;
    }
    return false;
};

export const validateModal = (actionType, StateActionData, inputData, t, permissions = {}) => {
    const { values, actionData } = StateActionData;
    const dateArrival = values ? joinFormDateTime(values["arrival-date"], values["arrival-hour"]) : null;
    const dateDeparture = values ? joinFormDateTime(values["departure-date"], values["departure-hour"]) : null;
    const deleteWord = t("delete");
    const customToast = [];
    let validations = {};
    let avoidDefError = false;
    switch (actionType) {
        case "updateGuestData":
            validations["name"] = validateNoEmpty(values["name"]);
            validations["surname"] = validateNoEmpty(values["surname"]);
            break;
        case "execute-checkin":
            const { numberOfGuests, numberOfRooms } = inputData.data.stayBookings;
            validations["modal-checkin-main-guest-select"] = validateNoEmpty(values["modal-checkin-main-guest-select"]);
            let countUsers = 0;
            for (let index = 0; index < numberOfRooms; index++) {
                validations[`modal-checkin-room-select-${index}`] = validateNoEmpty(
                    values[`modal-checkin-room-select-${index}`]
                );
                validations[`modal-checkin-guest-name-select-${index}`] = validateNoEmpty(
                    values[`modal-checkin-guest-name-select-${index}`]
                );

                countUsers += values[`modal-checkin-guest-name-select-${index}`]?.length ?? 0;
            }
            if (countUsers - (numberOfRooms - 1) < numberOfGuests) {
                validations["all-guest-must-be-selected"] = false;
                customToast.push(t("all-guest-must-be-selected"));
            } else {
                validations["all-guest-must-be-selected"] = true;
            }
            break;
        case "REQUEST_MODIFICATION":
            validations["request-modification-comments"] = validateNoEmpty(values["request-modification-comments"]);
            break;
        case "add-booking":
            validations["booking-code"] =
                validateNoEmpty(values["booking-code"]) && validateNonSpecialCharactes(values["booking-code"]);
            if (!validateNonSpecialCharactes(values["booking-code"])) {
                customToast.push(t("booking-code-has-not-valid-characters"));
            }
            validations["name"] = validateNoEmpty(values["name"]);
            validations["surname"] = validateNoEmpty(values["surname"]);
            validations["email"] = validateNoEmpty(values["email"]) && validateEmail(values["email"]);
            validations["rooms-reserved"] = validatePositive(values["rooms-reserved"]);
            validations["number-of-guests"] = validatePositive(values["number-of-guests"]);
            validations["arrival-date"] =
                validateNoEmpty(values["arrival-date"]) &&
                !dateBeforNow(joinFormDateTime(values["arrival-date"], "23:59"));
            if (!validations["arrival-date"]) {
                customToast.push(t("invalid-date"));
            }

            break;

        case "edit-documentation":
            if (!values["documentNumber"]?.trim() && values["dateOfIssue"]?.trim() && values["documentType"]) {
                validations["documentNumber"] = false;
            }
            if (!values["documentType"] && values["dateOfIssue"]?.trim() && values["documentNumber"]?.trim()) {
                validations["documentTypeSelect"] = false;
            }

            if (!values["documentType"]?.trim() && !values["dateOfIssue"]?.trim() && values["documentNumber"]) {
                validations["documentTypeSelect"] = false;
            }

            if (!values["documentType"]?.trim() && !values["documentNumber"]?.trim() && values["dateOfIssue"]) {
                validations["documentTypeSelect"] = false;
                validations["documentNumber"] = false;
            }

            break;
        case "add-companion":
        case "edit-companion":
            validations["name"] = validateNoEmpty(values["name"]);
            validations["surname"] = validateNoEmpty(values["surname"]);
            validations["room-select"] = validateNoEmpty(values["room"]);
            validations["email"] = values["email"]?.trim() ? validateEmail(values["email"]) : null;

            break;
        case "update-settings-delete-mobile-app":
            validations["delete-app"] = validateNoEmpty(values["delete-app"]);
            validations["delete-app"] = values["delete-app"] === deleteWord.toUpperCase();
            break;
        case "add-mobile-app":
            validations["app-name"] = validateNoEmpty(values["app-name"]);

            const containsForbiddenWord = forbiddenWords.some((forbiddenWord) =>
                values["app-name"].toLowerCase().includes(forbiddenWord.toLowerCase())
            );

            if (containsForbiddenWord) {
                validations["app-name"] = false;
            }

            // return;
            break;
        case "add-group-to-grid":
            if (values["option-selected"] !== "new-group") {
                validations["select-group-list-channels"] = validateNoEmpty(values["select-group-list-channels"]);
            } else {
                validations["name-group"] = validateNoEmpty(values["name-group"]);
            }
            break;
        case "edit-grid-name":
            validations["edit-the-name-of-the-grid"] = validateNoEmpty(values["edit-the-name-of-the-grid"]);
            break;
        case "add-group-channel":
            validations["name-group-selected"] = validateNoEmpty(values["name-group-selected"]);
            break;
        case "add-grid-channels":
            validations["name-grid-channel"] = validateNoEmpty(values["name-grid-channel"]);
            break;
        case "add-corporate-channel-property":
            if (values["create-corp-channel-opt"] === "create-global") {
                validations["select-global-corporate-channel"] = values["select-global-corporate-channel"]?.length > 0;
            } else {
                validations["create-corp-channel-name"] = validateNoEmpty(values["create-corp-channel-name"]);
            }
            break;
        case "add-corporate-channel-without-global-management":
            validations["name-corporate-channel"] = validateNoEmpty(values["name-corporate-channel"]);
            break;
        case "edit-move-channel-group":
            validations["edit-move-channel-group-position"] = validateNoEmpty(
                values["edit-move-channel-group-position"]
            );
            break;
        case "edit-name-channel":
            validations[`modal-edit-name-${values["langDefault"]}`] = validateNoEmpty(
                values["langsValues"]?.[values["langDefault"]]?.text
                    ? values["langsValues"]?.[values["langDefault"]]?.text
                    : ""
            );
            break;
        case "add-channel-to-group-channel":
            validations["channels-to-add-group"] = validateNoEmpty(values["channels-to-add-group"]);
            if (!validations["channels-to-add-group"]) {
                customToast.push(t("error-select-at-least-one"));
                avoidDefError = true;
            }
            break;
        case "add-corporate-channel":
            validations["name-new-corporate-channel"] = validateNoEmpty(values["name-new-corporate-channel"]);
            break;
        case "area-allow-booking-save":
            if (values["allow"] === "booking-allowed") {
                validations["people-number"] = validatePositive(values["people-number"]);
            }
            break;
        case "new-guest":
            validations.name = validateNoEmpty(values["name"]);
            validations.surname = validateNoEmpty(values["surname"]);
            validations["room-number"] = validateNoEmpty(values["room-number"]);
            if (values["arrival-date"] === undefined) {
                values["arrival-date"] = new window.ZDate().toDate().split("T")[0];
                values["arrival-hour"] = new window.ZDate().toDate().split("T")[1].split(".")[0].substring(0, 5);
            }
            validations["arrival-date"] = validateNoEmpty(values["arrival-date"]);
            validations["arrival-hour"] = validateNoEmpty(values["arrival-hour"]);

            //Optionals
            if (values["email"]) {
                validations.email = validateEmail(values["email"]);
            }
            if (values["mobile-phone"]) {
                validations["mobile-phone"] = validatePhone(values["mobile-phone"]);
            }

            if (values["departure-date"] || values["departure-hour"]) {
                validations["departure-date"] = validateNoEmpty(values["departure-date"]);
                validations["departure-hour"] = validateNoEmpty(values["departure-hour"]);

                if (values["departure-date"] && values["arrival-date"]) {
                    validations["departure-date"] = dateDeparture >= dateArrival;

                    if (values["departure-date"] === values["arrival-date"]) {
                        validations["departure-hour"] = values["departure-hour"] > values["arrival-hour"];
                        if (!validations["departure-hour"]) {
                            customToast.push(t("arrival-before-departure"));
                        }
                    }
                }

                if (dateDeparture < now) {
                    validations["departure-date"] = false;
                    customToast.push(t("past-date-no-valid"));
                }

                if (dateDeparture < dateArrival) {
                    validations["departure-date"] = false;
                    customToast.push(t("arrival-before-departure"));
                }
            }

            if (dateArrival < now) {
                validations["arrival-date"] = false;
                customToast.push(t("past-date-no-valid"));
            }

            break;
        case "change-room":
            if (inputData.rooms.length === 1) {
                validations[`room-${inputData.rooms[0].roomID}`] = validateNoEmpty(
                    values[`room-${inputData.rooms[0].roomID}`]
                );
            } else {
                let toChange = false;
                inputData.rooms.map((room) =>
                    values[`room-${room.roomID}`] && values[`room-${room.roomID}`].length > 0 ? (toChange = true) : null
                );
                if (!toChange) {
                    validations[`room-${inputData.rooms[0].roomID}`] = false;
                    customToast.push(t("error-select-at-least-one"));
                } else {
                    //check that destiny room are not duplicated
                    let arrayValues = [];
                    let duplicated = false;
                    inputData.rooms.map((room) =>
                        values[`room-${room.roomID}`] &&
                        values[`room-${room.roomID}`].length > 0 &&
                        arrayValues.indexOf(values[`room-${room.roomID}`][0]) > -1
                            ? (duplicated = true)
                            : arrayValues.push(values[`room-${room.roomID}`][0])
                    );

                    if (duplicated) {
                        validations[`room-${inputData.rooms[0].roomID}`] = false;
                        customToast.push(t("same-room-error"));
                    }
                }
            }

            break;
        case "add-guest-group":
        case "add-room-group":
            validations.name = validateNoEmpty(values["name"]);
            break;
        case "batch-assign-to-group":
            validations["guest-groups"] = validateNoEmpty(values["guest-groups"]);
            break;
        case "batch-room-assign-to-group":
            validations["room-groups"] = validateNoEmpty(values["room-groups"]);
            break;
        case "assign-to-group":
            /* validations["guest-groups"] = validateNoEmpty(values["guest-groups"]);

            if (!validations["guest-groups"]) {
                customToast.push(t("error-select-at-least-one"));
            }*/
            break;
        case "device-assign":
        case "device-authorise-and-assign":
            if (values["assign-device-id"]?.length <= 0 || !values["assign-device-id"]) {
                validations["assign-device-id"] = false;
            } else {
                validations["assign-device-id"] = true;
            }
            break;
        case "checkout":
            const roomsSelected = values["modal-checkout-rooms-checked"]?.trim();
            if (!roomsSelected) {
                avoidDefError = true;
                validations["checkout"] = false;
                customToast.push(t("error-select-at-least-one"));
            } else {
                validations["checkout"] = true;
            }
            break;
        case "cleaning-update":
            const date = values["cleaning-date"];
            const hour = values["cleaning-hour"];

            validations["cleaning-date"] = validateNoEmpty(date);
            validations["cleaning-hour"] = validateNoEmpty(hour);

            validations["responsible"] = actionData["responsible"] ? true : false;

            const str = date && hour ? `${date} ${hour}` : date;

            if (!str || !isValidDate(new window.ZDate(str))) {
                validations["cleaning-date"] = false;
                validations["cleaning-hour"] = false;
            } else if (new window.ZDate(str) > now) {
                validations["cleaning-date"] = false;
                validations["cleaning-hour"] = false;
                customToast.push(t("future-date-no-valid"));
            }

            break;
        case "chromecast-change-name":
            validations[actionType] = validateNoEmpty(values[actionType]);
            break;
        case "chromecast-assign":
        case "chromecast-move-room":
        case "tv-move-room":
        case "chromecast-authorize-assign":
            if (!values[actionType]) {
                validations[actionType] = false;
            } else {
                validations[actionType] = validateNoEmpty(values[actionType][0]);
            }
            break;
        case "add-room":
            validations["room"] = validateNoEmpty(values["room"]);
            break;
        case "create-new-input-group":
        case "edit-input-group":
            validations["input-group-name"] = validateNoEmpty(values["input-group-name"]);
            validations["inputs-type-duplicated"] = true;
            validations["inputs-name-duplicated"] = true;
            let inputTypesInUse = [];
            let inputNamesInUse = [];
            let inputTypeDuplicated = false;
            let inputNameDuplicated = false;

            //clean previous validations
            for (let index = 0; index < 10; index++) {
                if (validations[`input-${index}-name`]) {
                    validations[`input-${index}-name`] = null;
                }
                if (validations[`input-${index}-type`]) {
                    validations[`input-${index}-type`] = null;
                }
                if (validations[`input-${index}-device`]) {
                    validations[`input-${index}-device`] = null;
                }
            }

            for (let index = 0; index < values["inputs-number"]; index++) {
                validations[`input-${index}-name`] = validateNoEmpty(values[`input-${index}-name`]);
                validations[`input-${index}-type`] = validateNoEmpty(values[`input-${index}-type`]);
                const inputType = values[`input-${index}-type`]?.[0];
                const inputName = values[`input-${index}-name`];
                if (inputType) {
                    if (!inputTypesInUse.includes(inputType)) {
                        inputTypesInUse.push(inputType);
                    } else if (inputTypesInUse?.length > 0) {
                        validations[`input-${index}-type`] = false;
                        inputTypeDuplicated = true;
                    }
                }
                if (inputName) {
                    if (!inputNamesInUse.includes(inputName)) {
                        inputNamesInUse.push(inputName);
                    } else if (inputNamesInUse?.length > 0) {
                        validations[`input-${index}-name`] = false;
                        inputNameDuplicated = true;
                    }
                }
            }

            if (inputTypeDuplicated) {
                validations["inputs-type-duplicated"] = false;
                avoidDefError = true;
                customToast.push(t("duplicated-input-type"));
            } else if (inputNameDuplicated) {
                validations["inputs-name-duplicated"] = false;
                avoidDefError = true;
                customToast.push(t("duplicated-input-name"));
            } else {
                validations["inputs-number"] = true;
            }
            break;

        case "topology-edit-floorname":
        case "topology-edit-wingname":
            validations[actionType] = validateNoEmpty(values[actionType]);
            if (actionType === "topology-edit-wingname") {
                if (values?.data) {
                    const arrWings = values?.data
                        .filter((w) => {
                            return w.id !== values?.id;
                        })
                        .map((w) => {
                            return w.name;
                        });

                    if (arrWings.includes(values[actionType])) {
                        validations[actionType] = false;
                    }
                }
            }
            break;
        case "topology-edit-pmsnumber":
            validations[actionType] = validateNoEmpty(values[actionType]);
            if (values[actionType].indexOf("[room_number]") === -1) {
                validations[actionType] = false;
                customToast.push(t("pmsnumber-no-valid"));
            }
            break;
        case "create-content":
            validations["content-type"] = validateNoEmpty(values["content-type"]);

            if (validations["content-type"] && !values["template-id"]) {
                validations["template-id"] = false;
                avoidDefError = true;
                customToast.push(t("select-template"));
            }

            break;
        case "create-welcome-content":
            validations["welcome-type"] = validateNoEmpty(values["welcome-type"]);
            break;
        case "edit-template-name":
        case "save-template":
            validations["en"] = validateNoEmpty(values["en"]);
            validations["es"] = validateNoEmpty(values["es"]);
            break;

        case "email-booking-notification":
            // validations[actionType] = validateEmail(values["email-booking-notification"]);

            if (
                (!values["email-booking-notification"] || values["email-booking-notification"] === "") &&
                validateNoEmpty(values["inputs"])
            ) {
                validations[actionType] = true;
            }
            break;

        case "edit-role":
        case "add-manager-role":
            validations["role-name"] = validateNoEmpty(values["role-name"]);
            validations["role-accesses"] = validateNoEmpty(values["role-accesses"][0]);
            break;
        case "edit-user":
        case "add-manager-user":
            validations["user-name"] = validateNoEmpty(values["user-name"]);
            validations["user-email"] = validateEmail(values["user-email"]);
            validations["properties"] = validateNoEmpty(values["properties"]);
            validations["roles"] = validateNoEmpty(values["roles"]);
            break;
        case "add-property-group":
        case "edit-property-group":
            validations["name"] = validateNoEmpty(values["name"]);
            validations["properties"] = validateNoEmpty(values["properties"]);
            break;
        case "return-selected-asset":
            if (!validateNoEmpty(values["browser-media-library-items-selected"])) {
                validations["browser-media-library-items-selected"] = validateNoEmpty(
                    values["browser-media-library-items-selected"]
                );
                avoidDefError = true;
                customToast.push(t("error-select-at-least-one"));
            }

            break;
        case "edit-design":
        case "add-design":
            validations["design-name"] = validateNoEmpty(values["design-name"]);
            validations["design-themeId"] = validateNoEmpty(values["design-themeId"]);
            break;
        case "duplicate-in":
            const isSuperUser = Session.isSuperUser();
            if (!isSuperUser) {
                validations["chain"] = validateNoEmpty(values["chain"]);
            }
            validations["property"] = validateNoEmpty(values["property"]);
            validations["design"] = validateNoEmpty(values["design"]);
            break;
        case "save-translation":
            let defLang = values["defaultLang"];
            if (defLang && !validateNoEmpty(values[defLang])) {
                validations[defLang] = validateNoEmpty(values[defLang]);
                customToast.push(t("insert text for default language"));
                avoidDefError = true;
            } else if (defLang) {
                validations[defLang] = true;
            }
            break;
        case "content-add-folder":
            validations["name"] = validateNoEmpty(values["name"]);
            break;
        case "content-edit-folder-name":
            validations["name"] = validateNoEmpty(values["name"]);
            break;
        case "content-move-to":
            validations["folder"] = validateNoEmpty(values["folder"]);
            break;
        case "new-tag":
            validations["en"] = validateNoEmpty(values["en"]);
            validations["tag-type"] = validateNoEmpty(values["tag-type"]);
            break;
        // case "edit-tag":
        //     validations["en"] = validateNoEmpty(values["en"]);
        //     break;
        case "add-media-library-media":
            if (values.hasOwnProperty("lang")) {
                validations["lang"] = validateNoEmpty(values["lang"]);
            }
            if (values.hasOwnProperty("quality")) {
                validations["quality"] = validateNoEmpty(values["quality"]);
            }
            break;
        case "return-data-for-widget-items":
            if (!validateNoEmpty(values["image-selected"]) && !validateNoEmpty(values["url-selected"])) {
                validations["image-selected"] = false;
                avoidDefError = true;
                customToast.push(t("error-select-image"));
            }
            break;
        case "return-data-for-nav-items":
        case "edit-item-widget-nav":
            break;
        case "add-hotspot":
        case "edit-hotspot-name":
            validations["hotspot-name"] = validateNoEmpty(values["hotspot-name"]);

            if (validations["hotspot-name"] && !validateWhiteSpaces(values["hotspot-name"]) === false) {
                customToast.push(t("name-not-whitespaces"));
                validations["hotspot-name"] = false;
            }
            break;
        case "edit-hotspot-link":
            validations["redirect-type"] = validateNoEmpty(values["redirect-type"]);
            if (validations["redirect-type"]) {
                if (values["redirect-type"] === "url") {
                    validations["hotspot-link"] = validateNoEmpty(values["hotspot-link"]);
                    if (validations["hotspot-link"]) {
                        validations["hotspot-link"] = validateUrl(values["hotspot-link"]);
                    }
                }
            }
            break;
        case "add-new-mac-exception":
            validations["mac-exception-description"] = validateNoEmpty(values["mac-exception-description"]);
            validations["mac-exception-direction"] = validateMacDirection(values["mac-exception-direction"]);

            if (validations["mac-exception-direction"] === false) {
                customToast.push(t("not-a-valid-mac-adress"));
            }
            break;
        case "edit-mac-exception":
            validations["mac-exception-description"] = validateNoEmpty(values["mac-exception-description"]);
            break;
        case "new-alarm":
        case "edit-alarm":
        case "edit-room-alarm":
            validations[ALARM_TYPE.ID] = validateNoEmpty(values[ALARM_TYPE.ID]);
            if (values[ALARM_RECIPIENTS_TYPE.ID] === ALARM_RECIPIENTS_TYPE.ROOM && !values.isFromRoomDetail) {
                validations["stayGuestRoom"] = values["stayGuestRoom"].length > 0 ? true : false;
            } else if (values[ALARM_RECIPIENTS_TYPE.ID] === ALARM_RECIPIENTS_TYPE.GUEST) {
                validations[ALARM_RECIPIENTS_TYPE.GUEST] =
                    values[ALARM_RECIPIENTS_TYPE.GUEST].length > 0 ? true : false;
            }
            validations["-date"] = validateNoEmpty(values["-date"]);
            validations["-hour"] = validateNoEmpty(values["-hour"]);
            // doble validation - if date and our are typed manually
            if (
                validateNoEmpty(values["-date"]) &&
                validateNoEmpty(values["-hour"]) &&
                dateBeforNow(joinFormDateTime(values["-date"], values["-hour"]))
            ) {
                validations["-date"] = false;
                validations["-hour"] = false;
            } else if (
                validateNoEmpty(values["-date"]) &&
                validateNoEmpty(values["-hour"]) &&
                !dateBeforNow(joinFormDateTime(values["-date"], values["-hour"]))
            ) {
                validations["-date"] = true;
                validations["-hour"] = true;
            }
            if (validateNoEmpty(values[ALARM_TYPE.ID]) && values[ALARM_TYPE.ID] === ALARM_TYPE.TVCHANNEL) {
                validations[ALARM_TYPE.TVCHANNEL] = values[ALARM_TYPE.TVCHANNEL].length > 0 ? true : false;
            }
            break;
        case "new-passcode":
            validations["passcode-code"] = validateNoEmpty(values["passcode-code"]);

            if (validations["passcode-code"] === true) {
                if (values["passcode-code"] && values["passcode-code"].length < 4) {
                    validations["passcode-code"] = false;
                }
                if (values["passcode-code"]) {
                    validations["passcode-code"] = validateNonSpecialCharactes(values["passcode-code"]);
                }
            }

            validations["passcode-limit-of-devices"] = (() => {
                if (values["passcode-limit-of-devices-option-selected"] === "unlimited") {
                    return true;
                } else if (values["passcode-limit-of-devices-option-selected"] === "customised") {
                    if (values["passcode-limit-of-devices"] && Number(values["passcode-limit-of-devices"]) <= 0) {
                        return false;
                    } else {
                        return true;
                    }
                }
            })();
            validations["passcode-limit-of-devices-days"] = (() => {
                if (values["passcode-limit-of-devices-days-option-selected"] === "unlimited") {
                    if (
                        values["passcode-renovation-days"] &&
                        values["passcode-renovation-hours"] &&
                        Number(values["passcode-renovation-days"]) + Number(values["passcode-renovation-hours"]) <= 0
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                } else if (values["passcode-limit-of-devices-days-option-selected"] === "customised") {
                    if (
                        values["passcode-limit-of-devices-days"] &&
                        values["passcode-limit-of-devices-hours"] &&
                        Number(values["passcode-limit-of-devices-days"]) +
                            Number(values["passcode-limit-of-devices-hours"]) <=
                            0
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                }
            })();
            validations["passcode-limit-of-devices-hours"] = (() => {
                if (values["passcode-limit-of-devices-hours-option-selected"] === "unlimited") {
                    if (
                        values["passcode-renovation-days"] &&
                        values["passcode-renovation-hours"] &&
                        Number(values["passcode-renovation-days"]) + Number(values["passcode-renovation-hours"]) <= 0
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                } else if (values["passcode-limit-of-devices-hours-option-selected"] === "customised") {
                    if (
                        values["passcode-limit-of-devices-hours"] &&
                        values["passcode-limit-of-devices-days"] &&
                        Number(values["passcode-limit-of-devices-hours"]) +
                            Number(values["passcode-limit-of-devices-days"]) <=
                            0
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                }
            })();
            break;
        case "add-tax-zone":
        case "edit-tax-zone":
            validations["name"] = validateNoEmpty(values["name"]);
            let taxes = values["taxes"];
            if (taxes && taxes.length > 0) {
                // eslint-disable-next-line
                taxes.map((tax, index) => {
                    const suffix = `_${index}`;
                    if (tax.enabled) {
                        validations[`tax-name${suffix}`] = validateNoEmpty(tax.name);
                        if (tax.value !== undefined) {
                            if (
                                validateOperationThan("<", parseFloat(tax.value), 0.0) ||
                                validateOperationThan(">", parseFloat(tax.value), 100) ||
                                validateHasInitial0(tax.value)
                            ) {
                                validations[`tax-value${suffix}`] = false;
                            }
                        } else {
                            validations[`tax-value${suffix}`] = false;
                        }
                    }
                });
                if (!taxes.some((tax) => tax.enabled)) {
                    avoidDefError = true;
                    validations["taxes"] = false;
                    customToast.push(t("error-add-at-least-one-option"));
                }
            } else {
                avoidDefError = true;
                validations["taxes"] = false;
                customToast.push(t("error-add-at-least-one-option"));
            }
            break;
        case "add-product":
            validations["product-lang-default"] = validateNoEmpty(
                actionData && actionData["product-lang-default"] ? actionData["product-lang-default"] : ""
            );
            break;
        case "add-shop":
        case "create-shop":
            validations["add-shop-name"] = validateNoEmpty(
                actionData && actionData["add-shop-name"] ? actionData["add-shop-name"] : ""
            );

            validations["add-shop-currency"] = validateNoEmpty(
                values && values["add-shop-currency"] ? values["add-shop-currency"] : ""
            );

            validations["add-shop-taxes-zone"] = validateNoEmpty(
                values && values["add-shop-taxes-zone"] ? values["add-shop-taxes-zone"] : ""
            );

            if (validations && validations["add-shop-currency"] === false) {
                customToast.push(t("please-select-a-currency"));
            }

            break;
        case "edit-name-shop":
            if (actionData && typeof actionData === "object") {
                Object.keys(actionData).forEach((key) => {
                    if (key.match(/^default-name-shop-/)) {
                        validations[key] = validateNoEmpty(actionData[key]);
                    }
                });
            }
            break;
        case "create-facet-categorie-value":
            validations["name-category-shop"] = validateNoEmpty(
                actionData && actionData["name-category-shop"] ? actionData["name-category-shop"] : ""
            );
            validations["type-category-shop"] = validateNoEmpty(
                values && values["type-category-shop"] ? values["type-category-shop"] : ""
            );
            break;
        case "add-label":
        case "edit-label":
            if (values["translations"] && values["translations"].length > 0) {
                let defaultTranslation = values["translations"].filter((translation) => translation.isDefault);
                if (defaultTranslation && defaultTranslation.length > 0) {
                    defaultTranslation = defaultTranslation[0];
                    validations[`modalTranslation_value${defaultTranslation.index}`] = validateNoEmpty(
                        defaultTranslation.value
                    );
                }
            }
            break;
        case UPDATE_PRODUCT_SETTINGS:
            if (values["active-max-quantity-per-order"] && values["active-max-quantity-per-order"] === true) {
                let val = values && values["max-quantity-per-order"] ? values["max-quantity-per-order"] : 0;
                validations["max-quantity-per-order"] = validatePositive(val) && validateNoDecimals(val);
            }
            break;
        case ADD_PRODUCT_EXTRA:
            validations["add-product-extra-name"] = validateNoEmpty(
                values && values["add-product-extra-name"] ? values["add-product-extra-name"] : ""
            );
            break;
        case "add-channel":
            switch (values["add-channel-class"]) {
                case PRESET_CHANNEL.value:
                    validations["add-channel-preset"] = validateNoEmpty(
                        values && values["add-channel-preset"] ? values["add-channel-preset"] : ""
                    );
                    break;
                case CUSTOMISED_CHANNEL.value:
                    validations["add-channel-name"] = validateNoEmpty(
                        values && values["add-channel-name"] ? values["add-channel-name"] : ""
                    );
                    validations["add-channel-country"] = validateNoEmpty(
                        values && values["add-channel-country"] ? values["add-channel-country"] : ""
                    );
                    validations["add-channel-language"] = validateNoEmpty(
                        values && values["add-channel-language"] ? values["add-channel-language"] : ""
                    );
                    break;
                default:
                    break;
            }
            break;
        case "device-move-room":
            validations["roomtvId"] = validateNoEmpty(values["roomtvId"]);
            break;
        default:
            break;
    }

    customToast.map((msg) => toast.error(msg));
    return { validations, avoidDefError };
};

export const validateSection = (sectionName, values, t, extraData = null) => {
    const dateArrival = values ? joinFormDateTime(values["arrival-date"], values["arrival-hour"]) : null;
    const dateDeparture = values ? joinFormDateTime(values["departure-date"], values["departure-hour"]) : null;

    let validations = {};
    const customToast = [];
    switch (sectionName) {
        case "guest-data":
            if (values["email"]) {
                validations["email"] = validateEmail(values["email"]);
            }
            if (values["phone"]) {
                validations["phone"] = validatePhone(values["phone"]);
            }
            validations["name"] = validateNoEmpty(values["name"]);
            validations["surname"] = validateNoEmpty(values["surname"]);
            break;

        case "stay-data":
            if (values["arrival-date"] === undefined) {
                var date = new window.ZDate(
                    new window.ZDate().getTime() - new window.ZDate().getTimezoneOffset() * 60000
                );
                values["arrival-date"] = date.toISOString().substring(0, 10);
                values["arrival-hour"] = date.toISOString().substring(11, 16);
            }

            if (values["departure-date"] || values["departure-hour"]) {
                if (values["departure-date"] && !values["departure-hour"]) {
                    validations["departure-hour"] = false;
                    break;
                }
                validations["departure-date"] = validateNoEmpty(values["departure-date"]);
                if (values["departure-date"] && values["arrival-date"]) {
                    validations["departure-date"] = dateDeparture >= dateArrival;
                    if (values["departure-date"] === values["arrival-date"]) {
                        validations["departure-hour"] = values["departure-hour"] > values["arrival-hour"];
                        if (!validations["departure-hour"]) {
                            customToast.push(t("arrival-before-departure"));
                        }
                    }
                }

                if (dateDeparture < now) {
                    validations["departure-date"] = false;
                    customToast.push(t("past-date-no-valid"));
                }

                if (dateDeparture < dateArrival) {
                    validations["departure-date"] = false;
                    customToast.push(t("arrival-before-departure"));
                }
            }

            break;

        case "add-companion":
            validations["name"] = validateNoEmpty(values["name"]);
            validations["surname"] = validateNoEmpty(values["surname"]);
            // validations["room"] = validateNoEmpty(values["room"]);
            // if (values["email"]) {
            //     validations["email"] = validateEmail(values["email"]);
            // }

            break;

        case "topology-add-room":
            validations["number-of-rooms"] = validatePositive(values["number-of-rooms"]);
            validations["starter-room"] = validatePositive(values["starter-room"]);
            if (values["locations"]) {
                let locationsSelected = [];
                for (let index = 1; index <= parseInt(values["tvs-per-room"]); index++) {
                    const suffix = `_${index}`;
                    let locationValue =
                        values[`location${suffix}`] && values[`location${suffix}`].length > 0
                            ? values[`location${suffix}`][0]
                            : "";
                    if (validateNoEmpty(locationValue)) {
                        if (!locationsSelected.includes(locationValue)) {
                            locationsSelected.push(locationValue);
                        } else {
                            validations[`location${suffix}`] = false;
                        }
                    } else {
                        validations[`location${suffix}`] = false;
                    }
                }
            }
            break;

        case "topology-table-add-room":
            validations["room-name"] = validateNoEmpty(values["room-name"]);
            validations["wing"] = validateNoEmpty(values["wing"]);
            validations["floor"] = validateNoEmpty(values["floor"]);
            validations["room-number"] = validatePositive(values["room-number"]);
            if (values["locations"]) {
                let locationsSelected = [];
                for (let index = 1; index <= parseInt(values["tvs-per-room"]); index++) {
                    const suffix = `_${index}`;
                    let locationValue =
                        values[`location${suffix}`] && values[`location${suffix}`].length > 0
                            ? values[`location${suffix}`][0]
                            : "";
                    if (validateNoEmpty(locationValue)) {
                        if (!locationsSelected.includes(locationValue)) {
                            locationsSelected.push(locationValue);
                        } else {
                            validations[`location${suffix}`] = false;
                        }
                    } else {
                        validations[`location${suffix}`] = false;
                    }
                }
            }
            break;
        case "topology-add-floor":
        case "topology-add-wing":
            if (values["floors-number"] !== "0") {
                validations["number-of-rooms"] = validatePositive(values["number-of-rooms"]);
                if (values["locations"]) {
                    let locationsSelected = [];
                    for (let index = 1; index <= parseInt(values["tvs-per-room"]); index++) {
                        const suffix = `_${index}`;
                        let locationValue =
                            values[`location${suffix}`] && values[`location${suffix}`].length > 0
                                ? values[`location${suffix}`][0]
                                : "";
                        if (validateNoEmpty(locationValue)) {
                            if (!locationsSelected.includes(locationValue)) {
                                locationsSelected.push(locationValue);
                            } else {
                                validations[`location${suffix}`] = false;
                            }
                        } else {
                            validations[`location${suffix}`] = false;
                        }
                    }
                }
            }

            if (sectionName === "topology-add-wing") {
                validations["topology-order"] =
                    validatePositive(values["topology-order"]) && !values["arrPos"]?.includes(values["topology-order"]);

                validations[`wing-name`] = validateNoEmpty(values[`wing-name`]);
                validatePmsNumber({
                    pmsNumber: values["pms-number"],
                    validations,
                    customToast,
                    t,
                });
            }
            break;

        case "topology-add-floor-customize":
        case "topology-add-wing-customize":
            validatePmsNumber({
                pmsNumber: values["pms-number"],
                validations,
                customToast,
                t,
            });
            for (let index = 1; index <= extraData.floorsNumber; index++) {
                const suffix = `_${index}`;
                validations[`number-of-rooms${suffix}`] = validatePositive(values[`number-of-rooms${suffix}`]);
                validations[`order${suffix}`] = validatePositive(values[`order${suffix}`]);
                validations[`floor-name${suffix}`] = validateNoEmpty(values[`floor-name${suffix}`]);
                validations[`starter-room${suffix}`] = validatePositive(values[`starter-room${suffix}`]);
                if (values[`locations${suffix}`]) {
                    let locationsSelected = [];
                    for (let i = 1; i <= parseInt(values[`tvs-per-room${suffix}`]); i++) {
                        const nestedSuffix = `_${i}`;
                        let locationValue =
                            values[`location${suffix}${nestedSuffix}`] &&
                            values[`location${suffix}${nestedSuffix}`].length > 0
                                ? values[`location${suffix}${nestedSuffix}`][0]
                                : "";
                        if (validateNoEmpty(locationValue)) {
                            if (!locationsSelected.includes(locationValue)) {
                                locationsSelected.push(locationValue);
                            } else {
                                validations[`location${suffix}${nestedSuffix}`] = false;
                            }
                        } else {
                            validations[`location${suffix}${nestedSuffix}`] = false;
                        }
                    }
                }
            }
            break;

        case "topology-edit-room":
            validations["room-name"] = validateNoEmpty(values["room-name"]);
            validations["room-number"] = validatePositive(values["room-number"]);
            if (values["locations"]) {
                let locationsSelected = [];
                for (let index = 1; index <= parseInt(values["tvs-per-room"]); index++) {
                    const suffix = `_${index}`;
                    let locationValue =
                        values[`location${suffix}`] && values[`location${suffix}`].length > 0
                            ? values[`location${suffix}`][0]
                            : "";
                    if (validateNoEmpty(locationValue)) {
                        if (!locationsSelected.includes(locationValue)) {
                            locationsSelected.push(locationValue);
                        } else {
                            validations[`location${suffix}`] = false;
                        }
                    } else {
                        validations[`location${suffix}`] = false;
                    }
                }
            }
            break;

        case "add-new-area":
            validations["input_areaName"] = validateNoEmpty(values["input_areaName"]);
            validations["input_categoryName"] = validateNoEmpty(values["input_categoryName"]);

            break;

        case "add-new-area-nocategory":
            validations["input_areaName"] = validateNoEmpty(values["input_areaName"]);

            break;
        case "add-new-language":
            validations["input_lang"] = validateNoEmpty(values["input_lang"]);

            break;
        case "media-library-add-folder":
        case "media-library-edit-folder":
        case "media-library-edit-media":
            validations["media-library-name"] = validateNoEmpty(values["media-library-name"]);
            break;
        case "tag-single-value":
            validations["single-value-text"] = validateNoEmpty(values["single-value-text"]);
            break;
        case "edit-tag-values":
            validations["property-value"] = validateNoEmpty(values["property-value"]);
            break;
        case "new-message":
            validations["type"] = validateNoEmpty(values["type"]);
            if (values["expiration-hour"]) {
                validations["expiration-date"] = validateNoEmpty(values["expiration-date"]);
            }
            if (values["expiration-date"]) {
                validations["expiration-hour"] = validateNoEmpty(values["expiration-hour"]);
            }

            if (
                validateNoEmpty(values["expiration-date"]) &&
                validateNoEmpty(values["expiration-hour"]) &&
                dateBeforNow(joinFormDateTime(values["expiration-date"], values["expiration-hour"]))
            ) {
                validations["expiration-date"] = false;
                validations["expiration-hour"] = false;
            } else if (
                validateNoEmpty(values["expiration-date"]) &&
                validateNoEmpty(values["expiration-hour"]) &&
                !dateBeforNow(joinFormDateTime(values["expiration-date"], values["expiration-hour"]))
            ) {
                validations["expiration-date"] = true;
                validations["expiration-hour"] = true;
            }
            if (!values["destination"]) {
                validations["destination"] = false;
                // customToast.push(t("empty-recicipients"));
            } else {
                validations["destination"] = true;
            }

            if (extraData && extraData.projectLangs && validateNoEmpty(values["langActive"])) {
                validations[`subject-${values["langActive"]}`] = validateNoEmpty(
                    values[`subject-${values["langActive"]}`]
                );
            }
            break;
        case "send-later-message":
            validations["type"] = validateNoEmpty(values["type"]);
            if (!values["destination"]) {
                validations["destination"] = false;
            } else {
                validations["destination"] = true;
            }
            if (extraData?.projectLangs && validateNoEmpty(values["langActive"])) {
                validations[`subject-${values["langActive"]}`] = validateNoEmpty(
                    values[`subject-${values["langActive"]}`]
                );
            }
            break;
        case "new-alarm":
            validations["alarm-type"] = validateNoEmpty(values["alarm-type"]);
            break;
        case "add-access-profile":
        case "edit-access-profile":
            validations["name"] = validateNoEmpty(values["name"]);
            if (extraData && extraData.namesAlreadyUsed && extraData.namesAlreadyUsed.includes(values["name"])) {
                validations["name"] = false;
            }
            if (validateNoEmpty(values["uploadbwmin"]) && validateNoEmpty(values["uploadbwmax"])) {
                validations["uploadbwmin"] = validateOperationThan(
                    "<=",
                    parseFloat(values["uploadbwmin"]),
                    parseFloat(values["uploadbwmax"])
                );
            }
            if (validateNoEmpty(values["downloadbwmin"]) && validateNoEmpty(values["downloadbwmax"])) {
                validations["downloadbwmin"] = validateOperationThan(
                    "<=",
                    parseFloat(values["downloadbwmin"]),
                    parseFloat(values["downloadbwmax"])
                );
            }
            break;
        case UPDATE_PRODUCT_EXTRA:
            validations["product-edit-extra-name"] = validateNoEmpty(values["product-edit-extra-name"]);

            if (
                values["product-edit-extra-enable-min"] &&
                values["product-edit-extra-enable-min"] === true &&
                values["product-edit-extra-enable-max"] &&
                values["product-edit-extra-enable-max"] === true
            ) {
                validations["product-edit-extra-min"] =
                    validateNoEmpty(`${values["product-edit-extra-min"]}`) &&
                    validateOperationThan(">", parseInt(values["product-edit-extra-min"]), 0) &&
                    validateOperationThan(
                        "<=",
                        parseInt(values["product-edit-extra-min"]),
                        parseInt(values["product-edit-extra-max"])
                    );

                validations["product-edit-extra-max"] =
                    validateNoEmpty(`${values["product-edit-extra-max"]}`) &&
                    validateOperationThan(">", parseInt(values["product-edit-extra-max"]), 0) &&
                    validateOperationThan(
                        ">=",
                        parseInt(values["product-edit-extra-max"]),
                        parseInt(values["product-edit-extra-min"])
                    );
            } else if (values["product-edit-extra-enable-min"] && values["product-edit-extra-enable-min"] === true) {
                validations["product-edit-extra-min"] =
                    validateNoEmpty(`${values["product-edit-extra-min"]}`) &&
                    validateOperationThan(">", parseInt(values["product-edit-extra-min"]), 0);
            } else if (values["product-edit-extra-enable-max"] && values["product-edit-extra-enable-max"] === true) {
                validations["product-edit-extra-max"] =
                    validateNoEmpty(`${values["product-edit-extra-max"]}`) &&
                    validateOperationThan(">", parseInt(values["product-edit-extra-max"]), 0);
            }

            if (values["product-edit-extra-choices"] && values["product-edit-extra-choices"].length > 0) {
                values["product-edit-extra-choices"].forEach((choice, index) => {
                    const suffix = `product-edit-choice-${choice.ref}`;
                    validations[`${suffix}-name`] = validateNoEmpty(choice.name);
                });
            }
            break;
        case "update-tune":
            if (values?.udp?.isEnabled) {
                validations[extraData.inputsName[1]] =
                    validateNoEmpty(values.udp.ip) && validate244IPaddress(values.udp.ip);
                validations[extraData.inputsName[2]] =
                    validateNoEmpty(values.udp.ip) &&
                    validatePositive(values.udp.port) &&
                    validateNoDecimals(values.udp.port);
            }
            if (values?.hls?.isEnabled) {
                validations[extraData.inputsName[4]] =
                    validateNoEmpty(values.hls.url) && (validateUrl(values.hls.url) || validateRtp(values.hls.url));
            }
            break;
        default:
            break;
    }
    if ((extraData && !extraData.noCustomToast) || !extraData) {
        customToast.map((msg) => toast.error(msg));
    }

    return validations;
};

export const validateField = (field, value, required) => {
    let validation = false;
    if (!value && !required) return true;
    switch (field) {
        case "email":
            validation = validateEmail(value);
            break;
        case "mobile-phone":
            validation = validatePhone(value);
            break;
        default:
            break;
    }
    return validation;
};

export const validatePmsNumber = ({ pmsNumber, validations, customToast, t }) => {
    if (pmsNumber && pmsNumber !== "" && pmsNumber.indexOf("[room_number]") === -1) {
        validations["pms-number"] = false;
        customToast.push(t("pmsnumber-no-valid"));
    } else {
        validations["pms-number"] = true;
    }
};
/**
 * This function is used to validate inputs and show a toast message if the validation fails
 * @param {...object} validations - Array of objects with the following structure {ref: React.RefObject, message: string}
 * @returns {boolean} - Returns true if all validations pass, false otherwise
 */
export const toastValidations = (...validations) => {
    if (validations?.length) {
        let valid = true;
        let error;
        validations.forEach((validation) => {
            if (validation) {
                if (!validateInput(validation?.ref)) {
                    if (!error) {
                        error = validation?.message;
                    }
                    valid = false;
                }
            }
        });
        if (!valid && error) {
            toast.error(error);
        }
        return valid;
    }
    return true;
};

/**
 * This function is used to validate an input and focus on it if the validation fails
 * The input should have a isValid() method to check if the input is valid
 * @param {React.RefObject} input - React ref object of the input to validate
 * @returns {boolean} - Returns true if the input is valid, false otherwise
 */
const validateInput = (input) => {
    if (!input?.current?.isValid?.()) {
        if (input?.current) {
            input.current.focus();
        }
        return false;
    }
    return true;
};
