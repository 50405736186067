const arrangeSize = (sizeInMb) => {
    let response = `${sizeInMb} Mb`;
    if (sizeInMb >= 1024) {
        response = `${(sizeInMb / 1024).toFixed(2)} Gb`;
    }
    return response;
};

export const getFileTooltip = (file, t) => {
    let _contentTypeTooltip = "";
    let acceptedFormats = {
        image: ["jpg, png, gif, eps, svg, webp"],
        channelMap: ["xml, tll, json, html"],
        video: [""],
        font: ["ttf, otf, woff, woff2"],
    };
    switch (file.type) {
        case "image":
            _contentTypeTooltip += `<div class='w-full mb-3 text-left'><p class='font-bold text-left'>${t(
                "images"
            )}</p><p class='text-left text-sm'>${t("allowed-formats")}: ${acceptedFormats[file.type].join(
                ","
            )}</p><p class='text-left text-sm'>${t("size-limit")}: ${arrangeSize(file.maxSize)}</p></div>`;
            break;
        case "font":
            _contentTypeTooltip += `<div class='w-full mb-3 text-left'><p class='font-bold text-left'>${t(
                "fonts"
            )}</p><p class='text-left text-sm'>${t("allowed-formats")}: ${acceptedFormats[file.type].join(
                ","
            )}</p><p class='text-left text-sm'>${t("size-limit")}: ${arrangeSize(file.maxSize)}</p></div>`;
            break;
        case "video":
            _contentTypeTooltip += `<div class='w-full mb-3 text-left'><p class='font-bold text-left'>${t(
                "videos"
            )}</p><p class='text-left text-sm'>${t("size-limit")}: ${arrangeSize(file.maxSize)}</p></div>`;
            break;
        case "channelMap":
            _contentTypeTooltip += `<div class='w-full mb-3 text-left'><p class='font-bold text-left'>${t(
                "files"
            )}</p><p class='text-left text-sm'>${t("allowed-formats")}: ${acceptedFormats[file.type].join(",")}</p>
            <p class='text-left text-sm'>${t("size-limit")}: ${t("unlimited")}</p></div>`;
            break;
        case "":
            alert("Unknown file type");
            break;
        default:
            break;
    }
    return _contentTypeTooltip;
};

export const ACCEPTEDFORMATS = {
    image: [".jpg", ".jpeg", ".png", ".gif", ".eps", ".svg", ".webp", ".avif"],
    video: ["video/*"],
    font: ["font/*", ".otf", ".ttf", ".woff", ".woff2"],
    channelMap: ["text/xml", "text/plain", "text/html", "application/json", ".TLL"],
};

export const checkFileType = (file, accept) => {
    if (accept && accept.length > 0) {
        const detectedName = (file.name + "").toLowerCase();
        const detectedType = (file.type + "").toLowerCase();
        if (detectedType) {
            if (accept.includes(detectedType)) {
                // Eg: font/ttf equal font/ttf
                return true;
            }
        }

        let found = false;
        Array.from(accept).forEach((a) => {
            if (!found) {
                a = (a + "").toLowerCase();
                if (a.match(/\w+\/(\w+|\*)/) && detectedType) {
                    // Check mime-type
                    const acceptParts = (a + "").toLowerCase().split("/");
                    if (acceptParts[1] === "*") {
                        const detectedParts = detectedType.split("/");
                        if (detectedParts.length > 1 && acceptParts[0] === detectedParts[0]) {
                            // Eg: font/* allow font/ttf
                            found = true;
                        }
                    }
                } else if (a.match(/\.\w+/) && detectedName) {
                    // Check extension
                    if (detectedName.endsWith(a)) {
                        // Eg: test.ttf endsWith .ttf
                        found = true;
                    }
                }
            }
            return a;
        });
        return found;
    }
    // Accept all
    return true;
};
