import React, { useContext, useEffect, useState } from "react";
import { POLLINTERVAL_15S } from "hooks/Utils/Utils";
import { useLazyQuery } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import { GET_DATA, GET_FILTERS_DATA } from "../utils/devicesQueries";
import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { arrangeData, arrangeFilterData } from "../utils/monitorUtils";
import { useModal } from "components/Modal";
import { toast } from "react-toastify";
import InfoToast from "components/Section/Hotel/Monitor/InfoToast";
import { DevicesCards } from "./models/DevicesCards";
import { addToastInfoItem, removeToastInfoItem } from "actions/tableActions";
import FilterCCsContext from "components/Section/Hotel/context/FilterCCsContext";

export const useListDevices = () => {
    const { open, close } = useModal();
    const [devices, setDevices] = useState(null);
    const [filters, setFilters] = useState(null);
    const [dashboardCards, setDashboardCards] = useState(null);
    const { permissions } = useSelector((state) => state.ui);
    const { toastInfoItems } = useSelector((state) => state.table);
    const wifiEnabled = permissions?.product?.wifi ? permissions?.product?.wifi : null;
    const castEnabled = permissions?.product?.cast ? true : false;

    const { page, search, sort, deviceModel, deviceFunction, deviceStatus, deviceLocation, size } =
        useContext(FilterCCsContext);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const orderBy = sort ? `orderBy: { field: "${sort?.field}", criteria: "${sort?.criteria?.toUpperCase()}" }` : "";

    const [executeDevicesQuery, { data, loading, error, refetch }] = useMSQuery(GET_DATA(wifiEnabled, orderBy), {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    const [executeFiltersQuery, filterData] = useLazyQuery(GET_FILTERS_DATA);

    const variables = {
        page,
        search,
        ...(sort ? { field: sort?.field } : {}),
        ...(sort ? { criteria: sort?.criteria.toUpperCase() } : {}),
        model: deviceModel,
        deviceFunction,
        deviceStatus,
        deviceLocation,
        size,
    };

    useEffect(() => {
        executeFiltersQuery();
    }, []);

    useEffect(() => {
        if (!loading && !error && data?.devices && data?.warnings && filterData?.data && !filterData?.loading) {
            const dataWifiLicenses = data?.wifiLicenses ? data?.wifiLicenses : null;
            const dataCards = DevicesCards(data?.warnings?.results, permissions, dataWifiLicenses, open);
            const tableDevices = arrangeData(data, t, castEnabled, permissions, open, close, refetch);
            const filters = arrangeFilterData(filterData?.data, t);
            setDashboardCards(dataCards);
            setDevices(tableDevices);
            setFilters(filters);
            checkWarnings(data?.devices?.results);
        }
    }, [data, filterData]);

    const checkWarnings = (items) => {
        items.forEach((device) => {
            if (device?.warning?.info === "running-task" && !toastInfoItems.includes(device.mac)) {
                dispatch(addToastInfoItem(device.mac));
                const message = `${device.name} ${t(`task-${device.warning.actionInfo}`)}`;
                toast.info(<InfoToast msg={message} />, {
                    autoClose: 10000,
                });
                setTimeout(() => {
                    dispatch(removeToastInfoItem(device.mac));
                }, 60000);
            }
        });
    };

    return {
        devices,
        filters,
        dashboardCards,
        count: data?.devices?.info?.count,
        getDevices: (deviceNumber) => executeDevicesQuery({ variables: { ...variables, size: deviceNumber || size } }),
        vanillaDevices: data,
    };
};
