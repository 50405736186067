import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//MODELS
import Shop from "./models/Shop";
import { useTranslation } from "react-i18next";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetSalesTablesHeaders from "../../Utils/Table/Headers/GetSalesTableHeaders";
import {
    setAvailableFilters,
    setHeaderButtons,
    setCountPosition,
    setItemsPerpage,
    setTableResults,
    setBatchActive,
    setRefreshData,
    setCustomError,
    setSearchable,
    setCheckable,
    setLoading,
    setActions,
    setHeaders,
    setCount,
    setError,
    setCustomConfig,
    cleanCheckedItems,
} from "../../../actions/tableActions";
import { Session } from "../../Utils/Session";
import { POLLINTERVAL_15S } from "../../Utils/Utils";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { getProjectLangs, parseTranslation } from "../../Utils/SalesUtils";
import { useSalesModalZafiroTranslations } from "components/Section/Services/Sales/modals/useSalesModalZafiroTranslations";
import { TYPE_QUERY } from "components/Section/Services/Sales/utils/QueriesTranslations";
import useModifyStateShop from "components/Section/Services/Sales/modals/useModifyStateShop";
import { useDeleteShopModal } from "components/Section/Services/Sales/modals/useDeleteShopModal";
import { useModalSalesBatchActions } from "../../../components/Section/Services/Sales/modals/ModalBatchActions/useModalSalesBatchAction";
import { TYPE_BATCH_ACTION } from "components/Section/Services/Sales/modals/ModalBatchActions/utils";
import { useAuth } from "hooks/Session/auth";

const ListShop = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currentPage, perPage, sortCriteria, refreshData, activeFilters, checkedItems } = useSelector(
        (state) => state.table
    );
    const { permissions } = useSelector((state) => state.ui);
    const { languages: projectLangs } = useAuth();

    const hasOrdersManagement = permissions?.services?.shopsOrders;
    const { open: openModalBatchActions } = useModalSalesBatchActions();
    const { open: openModalEditName } = useSalesModalZafiroTranslations();
    const { open: openModalModifyState } = useModifyStateShop();
    const { open: openModalDeleteShop } = useDeleteShopModal();

    const search = activeFilters?.search ? activeFilters.search : "";
    const state = activeFilters?.state ? activeFilters.state : "";

    const GET_ZAFIRO_SHOPS = gql`
        query {
            shops(page: ${currentPage} , size: ${perPage}
                ${sortCriteria ? sortCriteria : ""}
                ${getCurrentFilter(state, search)}
                ) {
                info {
                    count
                    page
                    size
                }
                results {
                    id
                    name: nameTranslations {
                        lang
                        text
                    }
                    token
                    users
                    customFields {
                        isActive
                        syncId: syncID
                    }
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_ZAFIRO_SHOPS, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    const SECTION_NAME = "list-shops";

    //Here
    const [langDefault, setLangDefault] = useState("en");

    useEffect(() => {
        executeQuery();
        dispatch(setItemsPerpage(9));
        Session.setSessionProp("idShop", null);
        dispatch(setLoading(true));
        dispatch(setAvailableFilters(GetAvailableFilters(SECTION_NAME)));
        dispatch(setHeaders(GetSalesTablesHeaders(SECTION_NAME, { hasOrdersManagement })));
        dispatch(setCustomError(t("no-shops-yet")));
        dispatch(setCountPosition("table-header"));
        dispatch(setBatchActive(BatchActions({ openModalBatchActions })));
        dispatch(setSearchable(true));
        dispatch(setCheckable(true));
        dispatch(
            setCustomConfig({
                pagination: true,
            })
        );
        dispatch(setActions(true));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (projectLangs) {
            const { langDefault } = getProjectLangs(projectLangs, t);
            setLangDefault(langDefault);
        }
        // eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        if (!loading && data?.shops?.results) {
            const tableResults = arrangeData(
                data.shops.results,
                t,
                langDefault,
                hasOrdersManagement,
                openModalEditName,
                openModalModifyState,
                openModalDeleteShop
            );
            dispatch(setTableResults(tableResults));
            dispatch(setLoading(false));
            Session.setSessionProp("vendure-shops", JSON.stringify(tableResults));
            dispatch(setCount(data.shops.info.count));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(
            setBatchActive(
                BatchActions({
                    openModalBatchActions,
                    checkedItems,
                    shopsNames: data?.shops?.results
                        ? data.shops.results
                              .filter((shop) => checkedItems.includes(shop.id))
                              .map((shop) => {
                                  return { id: shop.id, name: parseTranslation(shop.name, langDefault) };
                              })
                        : [],
                })
            )
        );
        // eslint-disable-next-line
    }, [checkedItems]);

    useEffect(() => {
        dispatch(setError(error, !!error));
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        if (sortCriteria === "" && data) {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 800);
        }
        //eslint-disable-next-line
    }, [sortCriteria]);

    useEffect(() => {
        if (refreshData && refetch) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
            dispatch(cleanCheckedItems());
        }
        // eslint-disable-next-line
    }, [refreshData]);

    return null;
};

export default ListShop;
const BatchActions = ({ openModalBatchActions, checkedItems, shopsNames }) => {
    return [
        {
            name: "delete",
            customModal: () => {
                openModalBatchActions({
                    type: TYPE_BATCH_ACTION.DELETE_SHOP,
                    subtitle: "delete-shops-confirm",
                    labels: shopsNames,
                    checkedItems,
                    title: "delete",
                    textsSuccess: "next-shops-have-been-deleted",
                    textsError: "next-shops-have-not-been-deleted",
                    apiPointer: "ZAFIRO",
                });
            },
        },
        {
            name: "set-as-available",
            customModal: () => {
                openModalBatchActions({
                    type: TYPE_BATCH_ACTION.AVAILABLE_SHOP,
                    subtitle: "set-as-available-shops-confirm",
                    labels: shopsNames,
                    checkedItems,
                    title: "set-as-available",
                    textsSuccess: "next-shops-have-been-modified-successfully",
                    textsError: "next-shops-have-not-been-modified-successfully",
                    apiPointer: "VENDURE",
                });
            },
        },
        {
            name: "set-as-unavailable",
            customModal: () => {
                openModalBatchActions({
                    type: TYPE_BATCH_ACTION.UNAVAILABLE_SHOP,
                    subtitle: "set-as-unavailable-shops-confirm",
                    labels: shopsNames,
                    checkedItems,
                    title: "set-as-unavailable",
                    textsSuccess: "next-shops-have-been-modified-successfully",
                    textsError: "next-shops-have-not-been-modified-successfully",
                    apiPointer: "VENDURE",
                });
            },
        },
    ];
};

const arrangeData = (
    data,
    t,
    langDefault,
    hasOrderPermission,
    openModalEditName,
    openModalModifyState,
    openModalDeleteShop
) => {
    const shops = [];
    const newData = data.map((s) => {
        return {
            id: s.id,
            name: parseTranslation(s.name, langDefault),
            users: s.users.length,
            available: s.customFields.isActive,
            syncId: s.customFields.syncId,
            token: s.token,
            openModalEditName: () => {
                openModalEditName({
                    VARIABLES: { id: s.id, token: s.token },
                    QUERY: TYPE_QUERY.SHOP,
                    title: "edit-name",
                });
            },
            openModalModifyState: () => {
                openModalModifyState({
                    VARIABLES: { id: s.id, token: s.token },
                    nameShop: parseTranslation(s.name, langDefault),
                    available: s?.customFields?.isActive ? true : false,
                });
            },
            openModalDeleteShop: () => {
                openModalDeleteShop({
                    VARIABLES: { id: s.id },
                    nameShop: parseTranslation(s.name, langDefault),
                });
            },
        };
    });
    if (newData) {
        newData.forEach((dataItem) => {
            shops.push(Shop(dataItem, t, hasOrderPermission));
        });
    }
    return shops;
};

const getCurrentFilter = (state, search) => {
    let filter = "";

    if (search || state) {
        filter = "filter:{ ";
        if (search) {
            filter += `search: "${search}" , `;
        }
        if (state) {
            filter += `state: ${state}, `;
        }
        filter += "}";
    }

    return filter;
};
