import React, { useEffect, useState } from "react";
import UseSelectWithSearch from "../useSelectWithSearch";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Session } from "../../hooks/Utils/Session";
import { gql } from "apollo-boost";
import { useMSQuery } from "../../hooks/GraphqlCalls/useMSQuery";
import { changeActionValues } from "../../actions/globalActions";
import { setLoadingModalContent } from "../../actions/uiActions";

import { useAuth } from "hooks/Session/auth";

const UseDuplicateContent = () => {
    const { t } = useTranslation();

    const { chain: loggedChain, project, projects } = useAuth();

    //Actions
    const dispatch = useDispatch();

    //Session data
    const hasCorporateUser = Session.hasCorporate();
    const isSuperUser = Session.isSuperUser();

    //States
    const [chain, setChain] = useState(loggedChain?.ref);
    const [property, setProperty] = useState(null);
    const [chainOptions, setChainOptions] = useState(null);
    const [propertyOptions, setPropertyOptions] = useState(!isSuperUser ? projects : null);
    const [designOptions, setDesignOptions] = useState(null);

    const GET_CHAINS = gql`
        {
            data: chains {
                results {
                    id: ref
                    name
                }
            }
        }
    `;
    const [getChains, chainsResponse] = useMSQuery(GET_CHAINS);

    const GET_PROPERTIES = gql`{
        data: designsForDuplicateContent(
          chainRef:"${isSuperUser ? chain : loggedChain?.ref}",
        ){
          results: projectsShortInfo { id:ref name  }
        }                 
    }`;
    const [getProperties, propertiesResponse] = useMSQuery(GET_PROPERTIES);

    const GET_DESIGNS = gql`{
        data: designsForDuplicateContent(
          chainRef:"${isSuperUser ? chain : loggedChain?.ref}",
          projectRef:"${property}"
        ){
          results: designsShortInfo { id name  }
        }                 
    }`;
    const [getDesigns, designsResponse] = useMSQuery(GET_DESIGNS, {
        fetchPolicy: "network-only",
    });

    //Listeners

    useEffect(() => {
        dispatch(setLoadingModalContent(true));

        if (isSuperUser) {
            getChains();
            onChainChange(loggedChain?.ref, true);
            onPropertyChange(project?.ref);
        } else {
            onPropertyChange(project?.ref);
            dispatch(
                changeActionValues({
                    chain: [loggedChain?.ref],
                })
            );
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (
            chainsResponse &&
            chainsResponse.data &&
            chainsResponse.data.data &&
            chainsResponse.data.data.results &&
            chainsResponse.networkStatus === 7
        ) {
            dispatch(setLoadingModalContent(false));
            Session.setSessionProp("updateSelectOptions", "chain");
            setChainOptions(chainsResponse.data.data.results);
        }
        // eslint-disable-next-line
    }, [chainsResponse.data, chainsResponse.networkStatus]);

    useEffect(() => {
        if (
            propertiesResponse &&
            propertiesResponse.data &&
            propertiesResponse.data.data &&
            propertiesResponse.data.data.results &&
            propertiesResponse.networkStatus === 7
        ) {
            dispatch(setLoadingModalContent(false));
            Session.setSessionProp("updateSelectOptions", "property");

            let corporate = [];
            if (hasCorporateUser) {
                corporate.push({ id: "CORPORATE", name: t("global-management") });
            }

            let designList = propertiesResponse.data.data.results;
            designList.sort((a, b) => (a.name ? String(a.name).localeCompare(String(b.name)) : 0));
            setPropertyOptions(corporate.concat(designList));
        }
        // eslint-disable-next-line
    }, [propertiesResponse.data, propertiesResponse.networkStatus]);

    useEffect(() => {
        if (
            designsResponse &&
            designsResponse.data &&
            designsResponse.data.data &&
            designsResponse.data.data.results &&
            designsResponse.networkStatus === 7
        ) {
            dispatch(setLoadingModalContent(false));
            Session.setSessionProp("updateSelectOptions", "design");
            setDesignOptions(designsResponse.data.data.results);
        }
        // eslint-disable-next-line
    }, [designsResponse.data, designsResponse.networkStatus]);

    //Functions

    const onChainChange = (chainRef, noCleanDesign) => {
        setChain(chainRef);
        dispatch(
            changeActionValues({
                property: null,
            })
        );
        dispatch(
            changeActionValues({
                design: null,
            })
        );
        if (!noCleanDesign) {
            setDesignOptions([]);
        }
        setTimeout(function () {
            getProperties();
        }, 100);
    };

    const onPropertyChange = (propertyRef) => {
        setProperty(propertyRef);
        dispatch(
            changeActionValues({
                design: null,
            })
        );
        setTimeout(function () {
            getDesigns();
        }, 300);
    };

    return (
        <>
            {propertyOptions ? (
                <>
                    {isSuperUser && chainOptions ? (
                        <div className="pb-5">
                            <span className={`block pb-2 first-capital`}>{t("chain")}</span>
                            <UseSelectWithSearch
                                data={{
                                    selectPlaceHolder: t("{{capitalize, capitalize}}", {
                                        capitalize: t("select chain"),
                                    }),
                                    oneSelected: true,
                                    optionData: chainOptions,
                                    selectedIds: [loggedChain?.ref],
                                    onChange: onChainChange,
                                    noSelect: true,
                                    sort: true,
                                    name: "chain",
                                    text: "chain",
                                    useOptionID: false,
                                }}
                            ></UseSelectWithSearch>
                        </div>
                    ) : null}
                    <div className="pb-5">
                        <span className={`block pb-2 first-capital`}>{t("property")}</span>
                        <UseSelectWithSearch
                            data={{
                                selectPlaceHolder: t("{{capitalize, capitalize}}", {
                                    capitalize: t("select property"),
                                }),
                                oneSelected: true,
                                optionData: propertyOptions,
                                selectedIds: [project?.ref],
                                onChange: onPropertyChange,
                                noSelect: true,
                                disabled: !chain,
                                name: "property",
                                text: "property",
                                useOptionID: false,
                            }}
                        ></UseSelectWithSearch>
                    </div>
                    <div className="pb-5">
                        <span className={`block pb-2 first-capital`}>{t("design")}</span>
                        <UseSelectWithSearch
                            data={{
                                selectPlaceHolder: t("{{capitalize, capitalize}}", { capitalize: t("select design") }),
                                oneSelected: true,
                                optionData: designOptions,
                                noSelect: true,
                                sort: true,
                                disabled: !chain || !property,
                                name: "design",
                                text: "design",
                                useOptionID: false,
                            }}
                        ></UseSelectWithSearch>
                    </div>
                    <div className="flex justify-items-start duplicate-content--warning">
                        <i className="icon icon-warning mr-3 text-lg"></i>
                        <p className="leading-7">{t("navigation-not-be-duplicated")}</p>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default UseDuplicateContent;
