import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//Components
import UseSelectOption from "./useSelectOption";
//Actions
import { setActiveFilters, getFilterQuery, setPage } from "../../actions/tableActions";
//Utils
import useComponentVisible from "../useComponentVisible";
import { parseBoolean } from "../../hooks/Utils/Utils";

const UseSelect = ({ index }) => {
    const { t } = useTranslation();

    //Store data
    const {
        name,
        label,
        data,
        itemType,
        icons,
        translatable,
        disabled,
        isEnum = false,
    } = useSelector((state) => state.table.availableFilters[index]);
    const activeFilters = useSelector((state) => state.table.activeFilters);
    const stateActiveValue = useSelector((state) => state.table.activeFilters[name]);

    const [selectedOption, setSelectedOption] = useState(stateActiveValue);

    const selected =
        typeof selectedOption === "object" ? selectedOption : { label: selectedOption, value: selectedOption };
    const activeValue = selected?.value;
    const activeLabel = selected?.label;

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //States
    const [selectActive, setSelectActive] = useState({});
    const [isEvaluable, setIsEvaluable] = useState(false);

    const isBooleanType = itemType === "boolean" ? true : false;

    //Actions
    const dispatch = useDispatch();

    const handleChangeFilters = (e) => {
        dispatch(setActiveFilters(name, e.currentTarget.getAttribute("data-option")));
        dispatch(setPage(Number(1)));
        dispatch(
            getFilterQuery({
                ...activeFilters,
                [name]: isEnum
                    ? { isEnum, value: e.currentTarget.getAttribute("data-option") }
                    : e.currentTarget.getAttribute("data-option"),
            })
        );
        setIsComponentVisible(false);
    };
    const getValueOption = (option, index) => {
        if (isBooleanType && index === 0) return null;
        else if (isBooleanType && index === 1) return "true";
        else if (isBooleanType && index === 2) return "false";
        return option + "";
    };
    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible]);

    //Listeners
    useEffect(() => {
        if ((name === "state" && itemType === "booking") || itemType === "roles") {
            setIsEvaluable(true);
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (itemType === "boolean" && stateActiveValue && parseBoolean(stateActiveValue)) {
            setSelectedOption(data[1]);
        } else if (itemType === "boolean" && stateActiveValue && !parseBoolean(stateActiveValue)) {
            setSelectedOption(data[2]);
        } else {
            setSelectedOption(stateActiveValue);
        }
        // eslint-disable-next-line
    }, [stateActiveValue]);

    return (
        <div
            ref={ref}
            className={`w-full h-10 ${disabled ? "bg-gray-300" : "bg-gray-200"} rounded relative ${
                selectActive.container
            } `}
        >
            <div
                className={`t-filter-ph ${activeValue ? "text-black" : "text-gray-600"} ${
                    disabled ? "disabled" : selectActive.text
                }`}
                onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                id={
                    itemType && name
                        ? `${name.toLowerCase()}-${itemType}-select`
                        : name
                        ? `dropdown-select-input-${name}`
                        : "dropdown-select-input"
                }
            >
                <div className="first-capital truncate">
                    {!activeValue
                        ? t(label ? label : name)
                        : isEvaluable
                        ? t(`${name}-${itemType}s-${activeLabel}`)
                        : activeLabel === "disabled" && name === "state"
                        ? t("warning-chromecasts-4")
                        : t(`${activeLabel}`)}
                </div>
            </div>
            {!disabled ? (
                <span
                    id="select-option-icon"
                    className={`field-icon icon-chevron animated ${selectActive.icon}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                ></span>
            ) : (
                <span className={`field-icon icon-chevron animated ${disabled ? "disabled" : "cursor-pointer"}`}></span>
            )}

            {isComponentVisible && !disabled ? (
                <div className="t-options-container p-2">
                    {data.map((opt, index) => {
                        const option = typeof opt === "object" ? opt : { label: opt, value: opt };
                        const id = option?.value ? option?.value : "all";
                        const filterValue = option?.value;
                        const display = option?.label;
                        return (
                            <div
                                className="h-10 hover:bg-gray-100"
                                onClick={handleChangeFilters}
                                id={
                                    itemType
                                        ? `option-${
                                              option?.value ? option?.value.replace(",", "-") : "all"
                                          }-${itemType}`
                                        : `option-${option?.value ? option?.value.replace(",", "-") : "all"}`
                                }
                                data-option={getValueOption(option?.value, index)}
                                key={index}
                            >
                                <UseSelectOption
                                    id={id}
                                    display={display}
                                    filterName={name}
                                    filterValue={filterValue}
                                    icons={icons}
                                    itemType={itemType}
                                    translatable={translatable}
                                    selected={activeValue}
                                />
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
};

export default UseSelect;
