import React, {
    Fragment,
    cloneElement,
    useContext,
    useRef,
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { forceString, normalizeToSearch } from "hooks/Utils/Utils";

import Dropdown from "components/Dropdown";
import Checkbox from "components/Inputs/Checkbox";
import Search from "components/TextInput/Search";
import Button from "components/Button";
import Icon from "components/Icon";
import TextInput from "components/TextInput";

import { GlobalContext } from "contexts/Global";

const MINIMUM_OPTIONS_TO_SEARCH = 9;

const Select = forwardRef(
    (
        {
            children,
            id,
            options,
            value,
            placeholder,
            multiple,
            onChange,
            designClass: customDesignClass,
            optionsContainerClass,
            disabled: forceDisabled,
            selectGroup,
            warning,
            error: err,
            search: forceSearch,
            searchPlaceholder,
            title,
            allowCustomOptions, // Allow custom options to be added
            customOptionFn, // Function to format the custom option
            customOptionPlaceholder, // Placeholder for the custom option (default Add item)
            allowUnselect: unselect = true, // Allow unselecting the selected option
            width,
            minWidth = "handler",
            maxWidth,
            onOpenChange,
            showSelectedItems = false, // This will show the selected labels instead "x items selected" (multiple only)
            showSelectedInfo = true, // This will show the selected items info in the dropdown (multiple only and with showSelectedItems disabled)
            labelColor,
            required,
        },
        ref
    ) => {
        if (!id) {
            throw new Error("Select component requires an id");
        }

        const allowUnselect = unselect && !required;

        const bodyRef = useRef(null);

        const { highlightComponent } = useContext(GlobalContext);

        const isGroupSelectionEnabled = selectGroup !== false && multiple;

        const { t } = useTranslation();
        const [open, setOpen] = useState(false);
        const [disabled, setDisabled] = useState(forceDisabled);
        const [disabledArrow, setDisabledArrow] = useState(false);
        const [selected, setSelected] = useState(
            multiple
                ? (Array.isArray(value) ? value : [value])?.filter((v) => v !== null && v !== undefined) || []
                : value
        );
        const someSelected = isSomeSelectedFn(selected, { multiple });

        const error = err || (required && !someSelected);

        const childrenArray = ((c) => {
            if (!c) {
                return null;
            }
            return Array.isArray(c) ? c.filter(Boolean) : [c];
        })(children);
        const initialOptions = options?.length ? options : childrenArray;
        const initialOptionsHash = initialOptions?.length
            ? JSON.stringify(initialOptions?.map((o) => ensureOptionFn(o)?.value))
            : null;

        const [handler, setHandler] = useState(null);
        const [body, setBody] = useState(null);

        const [allOptions, setAllOptions] = useState(initialOptions);

        if (options?.length && childrenArray?.length) {
            console.error("You have options and children, only options will be used", {
                id,
                options,
                children: childrenArray,
            });
        }

        const countOptions = allOptions?.length
            ? allOptions.map((o) => o?.options?.length || 1).reduce((a, b) => a + b)
            : 0;

        const showSearch = forceSearch ?? countOptions >= MINIMUM_OPTIONS_TO_SEARCH;

        const changeValue = (value) => {
            if (!multiple) {
                setAllOptions((allOptions || []).filter((o) => !o?.custom));
            }
            return changeValueFn(value, {
                allowUnselect,
                selected,
                onChange,
                setSelected,
            });
        };

        const addCustomOption = (value, options) => {
            if (value) {
                const clearCustomOptions = !!options?.clear;
                const currentOptions = (allOptions || []).filter((o) => (clearCustomOptions ? !o.custom : true));
                const newOption = ensureOptionFn(customOptionFn ? customOptionFn(value) : value);
                if (newOption) {
                    newOption.custom = true;
                    const newOptions = [...currentOptions, newOption];
                    setAllOptions(newOptions);
                    if (multiple) {
                        addValueFn(newOption?.value, {
                            allowUnselect,
                            multiple,
                            selected,
                            onChange,
                            setSelected,
                            isGroupSelectionEnabled,
                            allOptions: newOptions,
                        });
                    } else {
                        changeValueFn(newOption?.value, {
                            allowUnselect,
                            selected,
                            onChange,
                            setSelected,
                        });
                        setOpen(false);
                    }
                }
            }
        };

        const addValue = (value) => {
            const options = multiple ? allOptions : (allOptions || []).filter((o) => !o?.custom);
            return addValueFn(value, {
                allowUnselect,
                multiple,
                selected,
                onChange,
                setSelected,
                isGroupSelectionEnabled,
                allOptions: options,
            });
        };

        const removeValue = (value) => {
            return removeValueFn(value, {
                multiple,
                selected,
                onChange,
                setSelected,
            });
        };

        useEffect(() => {
            setAllOptions(initialOptions);
        }, [initialOptionsHash]);

        useEffect(() => {
            if (!checkValues(multiple ? selected : [selected], allOptions)) {
                if (process.env.NODE_ENV === "development") {
                    console.warn(`Select value not found in options`, {
                        id,
                        value: selected,
                        options: allOptions?.map((o) => ensureOptionFn(o)?.value),
                    });
                }
                setSelected(null);
            }
        }, [allOptions, selected]);

        useEffect(() => {
            if (multiple) {
                setSelected(
                    (Array.isArray(value) ? value : [value])?.filter((v) => v !== null && v !== undefined) || []
                );
            } else {
                setSelected(value);
            }
        }, [value]);

        useEffect(() => {
            let selectPlaceholder = placeholder ?? t("select-an-option");

            const selectedLabels = getSelectedLabelsFn(selected, { multiple, options: allOptions });
            let selectedHandlerContent = selectPlaceholder;

            if (multiple && selectedLabels?.length && showSelectedItems) {
                selectedHandlerContent = (
                    <div className="flex flex-wrap gap-1 ">
                        {selectedLabels.map((option, index) => {
                            const id = `selected-${index}-remove`;
                            return (
                                <SelectedLabelItem
                                    id={id}
                                    key={id}
                                    option={option}
                                    removeValue={removeValue}
                                    className="ml-1 mt-1"
                                    bgColorClass={labelColor}
                                />
                            );
                        })}
                    </div>
                );
            } else {
                const selectedLabel =
                    multiple && selected?.length
                        ? t("x items selected", { count: selected.length })
                        : getSelectedOptionDisplay(selectedLabels?.[0]);

                selectedHandlerContent = selectedLabel || selectPlaceholder;
            }

            setHandler(selectedHandlerContent);

            setBody(
                <SelectBody
                    id={`${id}-body`}
                    ref={bodyRef}
                    {...{
                        optionsContainerClass,
                        selected,
                        multiple,
                        someSelected,
                        selectedLabels,
                        allOptions,
                        title,
                        showSearch,
                        allowCustomOptions,
                        customOptionPlaceholder,
                        searchPlaceholder,
                        setOpen,
                        changeValue,
                        addValue,
                        addCustomOption: (value) => addCustomOption(value, { clear: !multiple }),
                        removeValue,
                        isGroupSelectionEnabled,
                        showSelectedInfo,
                        showSelectedItems,
                        labelColor,
                    }}
                />
            );
        }, [placeholder, allOptions, children, selected, showSearch, allowCustomOptions]);

        useEffect(() => {
            setDisabled(forceDisabled || (!body && !placeholder?.props?.onClick));
            setDisabledArrow(forceDisabled || !body);
        }, [body, forceDisabled]);

        useEffect(() => {
            if (disabled && open) {
                setOpen(false);
            }
        }, [disabled, open]);

        const fgColors = classNames({
            "border border-red-500": error,
            "border border-orange-100": !error && warning,
            "border border-zafiro-300": !error && !warning && open,
            "text-zafiro-300": open,
            "text-gray-700": disabled, // Disabled
            "text-gray-900": !open && !disabled && someSelected, // Showing selected option (closed)
            "text-gray-600": !open && !disabled && !someSelected, // Showing placeholder (closed)
        });

        const dropdownConfig = {
            id,
            showArrow: true,
            disabledArrow,
            designClass: {
                dropdown: classNames({
                    "select-none rounded inline-block flex items-center justify-center": true,
                    "h-10": !showSelectedItems,
                    "h-auto py-1": showSelectedItems,
                    "bg-gray-200": !disabled, // enabled
                    "bg-gray-300": disabled, // Disabled
                    [fgColors]: fgColors,
                    "font-bold": open, // open
                    "highlight-component": highlightComponent,
                }),
                handler: "truncate first-capital px-4 py-1",
                handlerIcon: "text-gray-900 px-3 py-1",
                ...customDesignClass,
            },
            ref,
            width,
            minWidth,
            maxWidth,
            open,
            setOpen,
            disabled,
            handler,
            body,
            tooltip: error || warning,
            tooltipType: error || warning ? "warning-tooltip" : null,
            float: true,
            dropdownDirection: "down",
            onOpenChange: (open) => {
                if (bodyRef?.current?.onOpenChange) {
                    bodyRef.current.onOpenChange(open);
                }
                if (onOpenChange) {
                    onOpenChange(open);
                }
            },
        };

        return <Dropdown {...dropdownConfig} />;
    }
);
Select.displayName = "Select";

/**
 * @param id {string}
 * @param disabled {boolean}
 * @param onClick {function}
 * @param href {string}
 * @param className {string}
 * @param children {React.Children}
 * @returns {JSX.Element}
 * @constructor
 */
const SelectOption = ({
    id: desiredId,
    disabled,
    multiple,
    isGroup,
    onClick,
    href,
    className,
    children,
    value,
    tabIndex,
    selected,
    tooltip,
}) => {
    if (onClick && href) {
        throw new Error("You can't use onClick and href at the same time");
    }

    const id = desiredId || `option-${value || new Date().getTime()}`;

    const hasAction = !disabled && onClick;

    const buttonClass = classNames({
        "w-full first-capital text-left whitespace-nowrap": true, //TODO first-capital should not be necessary
        "px-5": !multiple,
        "px-3": multiple,
        "opacity-50": disabled,
        "cursor-default": !hasAction,
        "cursor-pointer hover:text-zafiro-300": hasAction,
        "text-zafiro-400": selected && !isGroup,
        "pt-4 pb-2 font-bold": isGroup,
        "py-2": !isGroup,
        [className]: className,
    });

    const onClickEvent = (e) => {
        e.stopPropagation();
        if (typeof onClick === "function" && !disabled) {
            onClick(e);
        }
    };

    if (href) {
        return (
            <a
                id={id}
                href={disabled ? "#" : href}
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={tabIndex}
                data-tip={tooltip || null}
                data-for="dangerous-html-tooltip"
                className={buttonClass}
            >
                {children}
            </a>
        );
    }

    return (
        <button
            id={id}
            onClick={onClickEvent}
            tabIndex={tabIndex}
            className={buttonClass || ""}
            data-tip={tooltip || null}
            data-for="dangerous-html-tooltip"
        >
            {children}
        </button>
    );
};

const parseOptionFn = (option, selected, { setOpen, multiple, addValue, changeValue, isGroupSelectionEnabled }) => {
    if (option && React.isValidElement(option)) {
        return cloneElement(option, {
            tabIndex: 0,
            selected: isSelectedFn(option.props?.value, { multiple, selected }),
            onClick: (e) => {
                if (e) {
                    e.stopPropagation();
                }
                if (option.props?.disabled) {
                    return;
                }
                if (option.props.onClick) {
                    option.props.onClick(e);
                }
                if (multiple) {
                    addValue(option.props?.value);
                } else {
                    changeValue(option.props?.value);
                    setOpen(false);
                }
            },
        });
    } else if (option && typeof option === "object") {
        const isGroup = option?.options?.length;
        const currentValue =
            option?.value ??
            (isGroup && multiple
                ? option.options.filter((o) => !ensureOptionFn(o).disabled).map((o) => ensureOptionFn(o).value)
                : null);
        const toggleOption = (e) => {
            if (e) {
                e.stopPropagation();
            }
            if (option?.disabled) {
                return;
            }
            if (isGroup && isGroupSelectionEnabled) {
                addValue(currentValue);
            }
            if (option?.onClick) {
                option.onClick(e);
            }
            if (multiple) {
                addValue(currentValue);
            } else {
                changeValue(currentValue);
                setOpen(false);
            }
        };

        return (
            <Fragment key={option?.id}>
                <SelectOption
                    key={option?.id}
                    tabIndex={0}
                    id={option?.id}
                    value={currentValue}
                    multiple={multiple}
                    disabled={option?.disabled}
                    selected={isSelectedFn(currentValue, { multiple, selected })}
                    isGroup={isGroup}
                    onClick={!isGroup || isGroupSelectionEnabled ? toggleOption : null}
                    tooltip={option?.tooltip}
                >
                    {multiple && (!isGroup || isGroupSelectionEnabled) ? (
                        <div className="inline-block mr-2">
                            <Checkbox
                                id={`${option?.id}-checkbox`}
                                checked={isSelectedFn(currentValue, { multiple, selected })}
                                disabled={option?.disabled}
                                onChange={() => {
                                    toggleOption();
                                }}
                            />
                        </div>
                    ) : null}
                    {getOptionDisplay(option)}
                </SelectOption>
                {isGroup ? (
                    <div className={`${isGroupSelectionEnabled && "ml-4"}`}>
                        {option.options.map((o) =>
                            parseOptionFn(o, selected, {
                                setOpen,
                                multiple,
                                addValue,
                                changeValue,
                            })
                        )}
                    </div>
                ) : null}
            </Fragment>
        );
    } else if (option && typeof option === "string") {
        const currentValue = option;
        const toggleOption = (e) => {
            if (e) {
                e.stopPropagation();
            }
            if (multiple) {
                addValue(currentValue);
            } else {
                changeValue(currentValue);
                setOpen(false);
            }
        };
        return (
            <SelectOption
                key={option}
                tabIndex={0}
                id={option}
                value={currentValue}
                multiple={multiple}
                selected={isSelectedFn(currentValue, { multiple, selected })}
                onClick={toggleOption}
            >
                {multiple ? (
                    <div className="inline-block mr-2">
                        <Checkbox
                            id={`${option}-checkbox`}
                            checked={isSelectedFn(currentValue, { multiple, selected })}
                            onChange={() => {
                                toggleOption();
                            }}
                        />
                    </div>
                ) : null}
                {option}
            </SelectOption>
        );
    }
    return null;
};

const setValues = ({ values, onChange, setSelected }) => {
    const uniqueValues = values?.length ? [...new Set(values)]?.filter((v) => v !== null && v !== undefined) : null;
    if (onChange) {
        onChange(uniqueValues?.length ? uniqueValues : null);
    }
    setSelected(uniqueValues?.length ? uniqueValues : null);
};

const addValueFn = (
    value,
    { allowUnselect, multiple, selected, onChange, setSelected, isGroupSelectionEnabled, allOptions }
) => {
    const isAlreadySelected = isSelectedFn(value, { multiple, selected });

    let newValue;
    if (allowUnselect && isAlreadySelected) {
        newValue = selected?.filter((v) => (Array.isArray(value) ? !value?.includes(v) : v !== value));
    } else {
        newValue = ((val, sel) => {
            if (Array.isArray(val)) {
                return val?.concat(sel);
            } else if (sel?.concat(val)) {
                return sel?.concat(val);
            }
            return [...(sel || []), val];
        })(value, selected);
    }

    if (isGroupSelectionEnabled && !isAlreadySelected) {
        const parentOption = allOptions.filter((option) =>
            option?.options?.some(
                (o) =>
                    ensureOptionFn(o)?.value ||
                    [value]?.includes(ensureOptionFn(o)?.value) ||
                    value?.includes(ensureOptionFn(o)?.value)
            )
        );
        if (parentOption) {
            const groupOptions = parentOption?.map((o) => o?.options?.map((o) => ensureOptionFn(o)?.value)).flat(1);
            if (isSelectedFn(value, { multiple, selected })) {
                if (multiple && Array.isArray(value)) {
                    // Deselect group
                    newValue = selected?.filter((v) => !groupOptions.includes(v));
                } else {
                    // Deselect single option
                    newValue = selected?.filter((v) => v !== value);
                }
            }
        }
    }

    setValues({ values: newValue, onChange, setSelected });
};

const removeValueFn = (value, { multiple, selected, onChange, setSelected }) => {
    if (isSomeSelectedFn(selected, { multiple })) {
        const newValue = selected.filter((v) => v !== value);
        if (onChange) {
            onChange(newValue?.length ? newValue : null);
        }
        setSelected(newValue?.length ? newValue : null);
        return true;
    }
    return false;
};

const changeValueFn = (value, { allowUnselect, selected, onChange, setSelected }) => {
    const newValue = allowUnselect && value === selected ? null : value;
    if (onChange) {
        onChange(newValue);
    }
    setSelected(newValue);
};

const isSomeSelectedFn = (selected, { multiple }) => {
    if (multiple) {
        return Array.isArray(selected) && selected?.length > 0;
    }
    return selected || selected === 0 || selected === false;
};

const isSelectedFn = (value, { multiple, selected }) => {
    if (isSomeSelectedFn(selected, { multiple })) {
        if (multiple) {
            if (Array.isArray(value)) {
                return value?.every((v) => selected?.includes(v));
            }
            return Array.isArray(selected) && selected?.includes(value);
        }
        return selected === value;
    }
    return false;
};

const labelSearch = (label, search) => {
    const strLabel = normalizeToSearch(forceString(label));
    const strSearch = normalizeToSearch(forceString(search));
    return strLabel?.toLowerCase().includes(strSearch.toLowerCase());
};

const ensureOptionFn = (input) => {
    if (input && React.isValidElement(input)) {
        return {
            value: input.props?.value,
            label: input.props?.children || input.props?.label || input,
            selectedLabel: input.props?.selectedLabel,
            disabled: input.props?.disabled,
        };
    }
    if (typeof input === "object") {
        return input;
    }
    return {
        value: input,
        label: input,
        disabled: false,
    };
};

const getSelectedLabelsFn = (selected, { options, multiple }) => {
    const someSelected = isSomeSelectedFn(selected, { multiple });
    let names = null;

    if (someSelected && options?.length) {
        names = options
            .map((opt) => {
                const option = ensureOptionFn(opt);
                const isGroup = option?.options?.length;
                if (isGroup) {
                    // If is a group, find all selected options
                    if (multiple) {
                        const found = option.options
                            ?.filter((o) => selected?.includes(ensureOptionFn(o)?.value))
                            .map((o) => ensureOptionFn(o));
                        return found?.length ? found : null;
                    } else {
                        return (
                            ensureOptionFn(option.options?.find((o) => ensureOptionFn(o)?.value === selected)) || null
                        );
                    }
                }
                const found =
                    option?.value !== undefined &&
                    (multiple ? selected?.includes(option?.value) : option?.value === selected);
                return found ? option : null;
            })
            ?.flat(1)
            ?.filter((option) => option !== null);
    }

    return names;
};

// This function checks if the selected value is a valid option (exists in the options array)
const checkValue = (value, options) => {
    if (value === null || value === undefined) {
        return true;
    }
    if (!options?.length) {
        return false;
    }
    return options?.some((opt) => {
        const option = ensureOptionFn(opt);
        return option?.options ? option.options.some((o) => ensureOptionFn(o).value === value) : option.value === value;
    });
};

const checkValues = (values, options) => {
    if (values === null || values === undefined || (Array.isArray(values) && !values?.length)) {
        return true;
    }
    if (!values?.length || !options?.length) {
        return false;
    }
    return values?.every((value) => checkValue(value, options));
};

const SelectBody = forwardRef(
    (
        {
            id,
            optionsContainerClass,
            selected,
            multiple,
            setOpen,
            allOptions,
            title,
            showSearch,
            allowCustomOptions,
            customOptionPlaceholder,
            searchPlaceholder,
            someSelected,
            selectedLabels,
            changeValue,
            addValue,
            isGroupSelectionEnabled,
            removeValue,
            showSelectedInfo,
            showSelectedItems,
            labelColor,
            addCustomOption,
        },
        ref
    ) => {
        const searchRef = useRef(null);
        const customOptionRef = useRef(null);

        const { t } = useTranslation();

        const [searchValue, setSearchValue] = useState("");
        const [tmpOption, setTmpOption] = useState("");

        const parseOption = (option, selected) => {
            return parseOptionFn(option, selected, {
                setOpen,
                multiple,
                addValue,
                changeValue,
                isGroupSelectionEnabled,
            });
        };

        const filteredOptions = searchValue?.length
            ? allOptions
                  .map((option) => {
                      const optionLabel = getOptionDisplay(ensureOptionFn(option));
                      if (labelSearch(optionLabel, searchValue)) {
                          return option;
                      }
                      if (option?.options) {
                          const filteredGroupOptions = option?.options.filter((groupOption) => {
                              const groupOptionLabel = getOptionDisplay(ensureOptionFn(groupOption));
                              return labelSearch(groupOptionLabel, searchValue);
                          });
                          if (filteredGroupOptions.length) {
                              return { ...option, options: filteredGroupOptions };
                          }
                      }
                      return null;
                  })
                  .filter((option) => option)
            : allOptions;

        useImperativeHandle(ref, () => ({
            onOpenChange: (open) => {
                if (open && searchRef.current) {
                    searchRef.current.focus();
                }
                if (!open) {
                    setSearchValue("");
                }
            },
        }));

        const newOption = (value) => {
            if (value) {
                addCustomOption(value);
            }
            if (customOptionRef.current) {
                customOptionRef.current.clear();
                customOptionRef.current.focus();
                customOptionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
            setTmpOption(null);
        };

        return (
            <div
                className={classNames({
                    "max-h-30 overflow-y-scroll select-none z-500 mt-1 rounded shadow-lg text-left border border-gray-300 bg-white": true,
                    [optionsContainerClass]: !!optionsContainerClass,
                })}
            >
                {title}
                {showSearch ? (
                    <div className="sticky top-0 bg-white border-b border-gray-200 py-2 px-1">
                        <Search
                            ref={searchRef}
                            id={`select-search-${id}`}
                            value={searchValue}
                            placeholder={searchPlaceholder}
                            onChange={setSearchValue}
                            onKeyPress={(e) => {
                                if (e.key === "Escape") {
                                    setOpen(false);
                                }
                            }}
                        />
                    </div>
                ) : null}
                {multiple && someSelected && showSelectedInfo && !showSelectedItems ? (
                    <div className="p-2">
                        <div className="text-gray-900 px-1">{t("Selected items")}:</div>
                        <div className="flex flex-wrap p-1">
                            {selectedLabels?.map((option, index) => {
                                const id = `selected-${index}-remove`;
                                return (
                                    <SelectedLabelItem
                                        id={id}
                                        key={id}
                                        option={option}
                                        removeValue={removeValue}
                                        className="ml-1 mt-1"
                                        bgColorClass={labelColor}
                                    />
                                );
                            })}
                        </div>
                    </div>
                ) : null}
                {filteredOptions ? (
                    filteredOptions.map((o) => <>{parseOption(o, selected)}</>).filter((option) => option)
                ) : (
                    <div className="text-gray-700 px-5 py-3 whitespace-no-wrap">{t("no options available")}</div>
                )}
                {allowCustomOptions ? (
                    <div className="sticky bottom-0 bg-white border-t border-gray-200 p-1">
                        <TextInput
                            ref={customOptionRef}
                            id={`custom-value-${id}`}
                            placeholder={customOptionPlaceholder || t("add-item")}
                            onChange={newOption}
                            onInput={setTmpOption}
                        />
                        {tmpOption?.length ? (
                            <div className="absolute h-full bottom-0 flex items-center right-0 px-3">
                                <Button
                                    id={`custom-value-add-${id}`}
                                    onClick={() => newOption(customOptionRef.current.getValue())}
                                    className="bg-green-100 text-white font-bold rounded p-1"
                                >
                                    <Icon type="tick-right" size={1.2} />
                                </Button>
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        );
    }
);

const SelectedLabelItem = ({ id, option, removeValue, className, bgColorClass = "bg-blue-200" }) => {
    const handleRemove = (e) => {
        e.stopPropagation();
        removeValue(ensureOptionFn(option).value);
    };

    const buttonClassName = classNames(bgColorClass, "text-white", "text-sm", "rounded", "px-2", "py-1", className);

    return (
        <Button id={id} key={id} disabled={option?.disabled} className={buttonClassName} onClick={handleRemove}>
            <div className="flex justify-between items-center">
                {getSelectedOptionDisplay(option)}
                <Icon type="remove" className="pl-2" />
            </div>
        </Button>
    );
};

// This function is used to get the display value of an option
const getOptionDisplay = (option) => {
    if (option) {
        return option?.label || option?.name || option?.value || "Unknown";
    }
    return null;
};

const getSelectedOptionDisplay = (option) => {
    return option?.selectedLabel || option?.selectedName || getOptionDisplay(option);
};

export default Select;
