import { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";

import { UserNetworkContext } from "contexts/NetworkWarnings/UserNetwork";

import { getTableInfo } from "components/Section/Hotel/Services/getTableInformation";
import FilterCCsGuestUsersContext from "components/Section/Hotel/context/FilterCCsContext";

export const useGetCCguestNetwork = () => {
    const { setUpdatedData } = useContext(UserNetworkContext);
    const [tableLength, setTableLength] = useState(0);
    const { model: filterByModel, ownership: filterByOwnership } = useContext(FilterCCsGuestUsersContext);

    const modelFilterName = filterByModel.map((filter) => filter.name) || [];
    const model = filterByModel.length > 0 ? `model: ["${modelFilterName.join('", "')}"]` : "";

    const ownershipFilterId = filterByOwnership?.map((ownership) => ownership.id) || [];
    const ownershipFilter = filterByOwnership.length > 0 ? `ownership: [${ownershipFilterId.join(", ")}]` : "";

    const finalFilter = model || ownershipFilter ? `${model}, ${ownershipFilter}` : "";

    const [executeQuery, { data, loading }] = useLazyQuery(getTableInfo(finalFilter), {
        fetchPolicy: "network-only",
        pollInterval: 90000,
    });

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (data && !loading) {
            setUpdatedData(data?.getCCsGuestNetworkInfo);
            if (model.length === 0 && ownershipFilter.length === 0) {
                setTableLength(data?.getCCsGuestNetworkInfo?.data?.length);
            }
        }
    }, [data, loading]);

    return { tableLength };
};
