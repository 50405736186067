import React, { useContext, useState, useEffect } from "react";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import { getFromDAS } from "../../../../../hooks/Utils/Utils";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeGlobalAction, cleanAction } from "../../../../../actions/globalActions";
import { openModal, setModalContent, closeModal } from "../../../../../actions/uiActions";
import BrowserMediaLibraryModal from "../../../../../hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { setExecuteQuery } from "../../../../../actions/sectionActions";

import { GlobalContext } from "contexts/Global";
import ReactTooltip from "components/ReactTooltip";
import Parser from "hooks/Utils/Parser";

function ImageChannelSelector({
    number,
    dataComponent,
    setDataComponent,
    idCard = "image-channel-selector",
    widthImage = "9.5rem",
    heightImage = "7.4rem",
    action = "add-image-customised-channel",
    headerLogoAdjust = "text-lg",
    headerLogoText = "add-logo",
    headerIconAdjust = "icon icon-info text-2xl text-gray-800",
    textButton = "add-image",
    adjustButtonImage = null,
    dispatchActionDeleteImage = null,
    adjustButtonDeleteImage,
    containerImageAdjust = "bg-gray-900",
    defaultIcon = "icon-channel text-gray-800",
    type = "channel",
}) {
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const [tooltipLogo, setTooltipLogo] = useState("");
    const [srcImage, setSrcImage] = useState(null);
    const { values } = useSelector((state) => state.action);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const dispatch = useDispatch();

    const GET_INFO_LOGO = gql`
        query {
            libraryAsset(ref: "${dataComponent?.logoRef}") {
                response {
                    name
                }
            }
        }
    `;

    const [getInfoLogo, { data }] = useLazyQuery(GET_INFO_LOGO, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        getInfoLogo();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data?.libraryAsset?.response?.name) {
            setTooltipLogo(data.libraryAsset.response.name);
        }
    }, [data]);

    const openModalLibrary = () => {
        const modal = BrowserMediaLibraryModal(t, {
            text: "images-of-the-media-library",
            title: "choose-an-image",
            textAdjust: "",
            bodyAdjust: "w-10/12",
            executeSectionQuery: true,
            action: "widget-actions-media",
            buttonSelectedAssetName: "choose-an-image",
            closeAction: null,
            dataMediaLibrary: {
                lang: lang,
                selected_ref: null,
                type: "asset",
                select_multiple: false,
                add_asset: {
                    active: true,
                    return_added: false,
                },
            },
        });
        setTimeout(() => {
            dispatch(
                changeGlobalAction({
                    actionName: action,
                    values: { ...values, idCardImageChannelSelector: idCard },
                })
            );
        }, 500);
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    useEffect(() => {
        if (dataComponent?.logoRef) {
            setSrcImage(dataComponent.logoRef);
        }
    }, [dataComponent]);

    useEffect(() => {
        if (executeQuery && executeQuery.action === action && values["idCardImageChannelSelector"] === idCard) {
            setDataComponent({ ...dataComponent, logoRef: values["browser-media-library-items-selected"][0] });
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(cleanAction());
            setSrcImage(values["browser-media-library-items-selected"][0]);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    return (
        <div>
            <div className="flex items-center">
                <span className={`font-bold ${headerLogoAdjust} mr-2`}>{headerLogoText ? t(headerLogoText) : ""}</span>
                <i
                    data-for={`channel-info-${number}-tooltip`}
                    data-tip
                    className={` ${headerIconAdjust ? headerIconAdjust : ""}  `}
                ></i>

                <ReactTooltip
                    arrowColor="text-gray-200"
                    borderColor="text-gray-200"
                    border={true}
                    className="font-normal"
                    delayShow={250}
                    id={`channel-info-${number}-tooltip`}
                    type="light"
                >
                    {Parser(t("image-recommended-channel"))}
                </ReactTooltip>
            </div>
            <div className="mt-2">
                <div
                    id={`image-${type}-selector-container${number ? `-${number}` : ""}`}
                    style={{ width: widthImage, height: heightImage }}
                    className={`relative ${containerImageAdjust} overflow-hidden rounded flex items-center justify-center`}
                >
                    {dataComponent?.logoRef && srcImage ? (
                        <img
                            className="h-full w-full object-scale-down"
                            id={`image-${type}-selector-image${number ? `-${number}` : ""}`}
                            onError={() => {
                                setSrcImage(null);
                            }}
                            alt="something"
                            src={getFromDAS(srcImage, lang)}
                        />
                    ) : (
                        <i
                            id={`image-${type}-selector-icon${number ? `-${number}` : ""}`}
                            className={`icon text-5xl ${defaultIcon} `}
                        ></i>
                    )}
                    {dataComponent?.logoRef ? (
                        <div
                            className={`absolute flex justify-end bottom-0 left-0 bg-gray-800 bg-opacity-50 w-full ${
                                adjustButtonDeleteImage ? adjustButtonDeleteImage : ""
                            } `}
                        >
                            <div className="w-3/5 flex justify-between items-center px-1">
                                <i
                                    id={`delete-icon-image-${number}`}
                                    onClick={() => {
                                        setDataComponent({ ...dataComponent, logoRef: "" });
                                        setSrcImage(null);
                                        if (dispatchActionDeleteImage) {
                                            dispatch(
                                                setExecuteQuery({
                                                    action: dispatchActionDeleteImage,
                                                    hideLoading: true,
                                                })
                                            );
                                        }
                                    }}
                                    className="cursor-pointer text-white icon text-3xl icon-delete-editor"
                                ></i>
                                <i
                                    data-tip={`<strong>${tooltipLogo}</strong>`}
                                    data-for="dangerous-html-tooltip"
                                    className="text-white icon text-lg mt-1 icon-info"
                                ></i>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="mt-6 w-full">
                <button
                    id={`image-${type}-selector-button-select-image${number ? `-${number}` : ""}`}
                    onClick={openModalLibrary}
                    className={`w-full rounded py-1 font-bold text-white focus:outline-none bg-zafiro-600 ${
                        adjustButtonImage ? adjustButtonImage : ""
                    }`}
                >
                    {capitalizeFirst(t(textButton))}
                </button>
            </div>
        </div>
    );
}

export default ImageChannelSelector;
