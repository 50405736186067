import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withApollo } from "@apollo/react-hoc";

//Actions
import { showGlobalLoading } from "../../../../actions/uiActions";
import { capitalizeFirst, removeApolloCacheKeys } from "../../../../hooks/Utils/Utils";

//UTils
import { CSVLink } from "react-csv";
import _ from "lodash";
import { DEVICE_TYPES, getInputGroup } from "./ListDevicesUtils";
import { useListDevices } from "hooks/GraphqlCalls/Hotel/Monitoring/useListDevices";
import FilterCCsContext from "components/Section/Hotel/context/FilterCCsContext";

const ExportDevices = ({ startDataExport, setStartDataExport, client }) => {
    const hide_ips = ["127.0.0.1"];

    const { page, search, sort, deviceFunction, deviceModel, deviceLocation, deviceStatus, setSize } =
        useContext(FilterCCsContext);
    const { vanillaDevices, getDevices } = useListDevices();

    //Variables.
    let d = new window.ZDate();
    let exportFileName = `devices_${d.getDate()}_${d.getMonth() + 1}_${d.getFullYear()}.csv`;

    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //States.
    const [exportData, setExportData] = useState([]);
    const [exportHeaders, setExportHeaders] = useState([]);

    //Actions
    const dispatch = useDispatch();

    useEffect(() => {
        if (startDataExport) {
            dispatch(showGlobalLoading(true));
            getDevices(10000);
        }
        // eslint-disable-next-line
    }, [startDataExport, page, search, sort, deviceFunction, deviceModel, deviceLocation, deviceStatus]);

    useEffect(() => {
        if (startDataExport && vanillaDevices) {
            const ccs = vanillaDevices?.devices?.results;
            let rows = [];
            setExportHeaders([
                { label: capitalizeFirst(langStrings["mac"]), key: "mac" },
                { label: capitalizeFirst(langStrings["state"]), key: "state" },
                { label: capitalizeFirst(langStrings["ip"]), key: "ip" },
                { label: capitalizeFirst(langStrings["name"]), key: "name" },
                { label: capitalizeFirst(langStrings["room"]), key: "room" },
                { label: capitalizeFirst(langStrings["location"]), key: "location" },
                { label: capitalizeFirst(langStrings["type"]), key: "type" },
                { label: capitalizeFirst(langStrings["model"]), key: "model" },
                { label: capitalizeFirst(langStrings["tv-firmware"]), key: "tv-firmware" },
                { label: capitalizeFirst(langStrings["input-group"]), key: "input-group" },
                { label: capitalizeFirst(langStrings["authorized"]), key: "authorized" },
                { label: capitalizeFirst(langStrings["online"]), key: "connected" },
                { label: capitalizeFirst(langStrings["last-connection"]), key: "lastconnection" },
                { label: capitalizeFirst(langStrings["last-activity"]), key: "casting" },
                { label: capitalizeFirst(langStrings["wifi-signal"]), key: "signal" },
            ]);
            ccs.map((cc) => {
                let inputGroupName = "";
                if (cc.type !== DEVICE_TYPES.googleTV.value && cc.type !== DEVICE_TYPES.chromecast.value) {
                    inputGroupName = getInputGroup(cc.tvInputID, vanillaDevices?.tvInputs?.results)?.name;
                }
                rows.push({
                    mac: cc.mac ? cc.mac : "",
                    state: cc && cc.warning && cc.warning.info ? cc.warning.info : "",
                    ip: cc.ip && !hide_ips.includes(cc.ip) ? cc.ip : "",
                    name: cc.name ? cc.name : "",
                    room: cc.roomNumber ? cc.roomNumber : "",
                    location: cc.roomTVName ? cc.roomTVName : "",
                    type: cc.type ? cc.type : "",
                    model: cc.commercialName ? cc.commercialName : "",
                    "tv-firmware": cc.firmwareVersion ? cc.firmwareVersion : "",
                    "input-group": inputGroupName ? inputGroupName : "",
                    authorized:
                        cc && cc.warning && cc.warning.info && cc.warning.info === "unauthorized"
                            ? langStrings["no"]
                            : langStrings["yes"],
                    connected:
                        cc && cc.warning && cc.warning.info && cc.warning.info === "online"
                            ? langStrings["yes"]
                            : langStrings["no"],
                    lastconnection: cc.lastActivityDate ? new window.ZDate(cc.lastActivityDate).toLocaleString() : "",
                    casting: cc.lastActivity ? cc.lastActivity : "",
                    signal: cc.wifiSignal ? cc.wifiSignal : "",
                });
            });
            setExportData(rows);
            dispatch(showGlobalLoading(false));
            setTimeout(function () {
                document.querySelector("#exportLink").click();
                //remove apollo cache for next downloads
                removeApolloCacheKeys(client.cache, "chromecastsExport");
                setStartDataExport(false);
            }, 200);
        }
        // eslint-disable-next-line
    }, [vanillaDevices]);

    return (
        <CSVLink
            id={"exportLink"}
            className="hidden"
            data={exportData}
            headers={exportHeaders}
            filename={exportFileName}
        ></CSVLink>
    );
};

export default withApollo(ExportDevices);
