import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CORPORATE_APPEARANCE_TYPE, PROPERTY_APPEARANCE_TYPE } from "../../hooks/Utils/AppearancesUtils";
import { useTranslation } from "react-i18next";
import { useNavigate as useHistory } from "react-router-dom";
import { closeModal } from "../../actions/uiActions";

const UseModalDesignsTree = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //History
    const history = useHistory();
    //Store data
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { permissions } = useSelector((state) => state.ui);
    const allowLink = permissions?.design?.interfaceEditor;

    const navigateToDesign = (desginId) => {
        if (allowLink) {
            dispatch(closeModal());
            history(`/design/${desginId}`);
        }
    };

    const renderGlobalDesigns = () => {
        if (data[CORPORATE_APPEARANCE_TYPE].length === 0) {
            return null;
        } else if (data[CORPORATE_APPEARANCE_TYPE].length > 0) {
            /* GLOBAL DESIGNS */
            return (
                <div className="w-full grid grid-cols-2">
                    <div className="font-bold">
                        {t("{{capitalize, capitalize}}", { capitalize: t("global-designs") })}
                    </div>
                    <div id="list-designs-tree">
                        {
                            // eslint-disable-next-line
                            data[CORPORATE_APPEARANCE_TYPE].map((design) => (
                                <div
                                    key={design.ID}
                                    className={`break-all mb-2 ${allowLink ? "text-blue-300 cursor-pointer" : ""}`}
                                    onClick={() => navigateToDesign(design.ID)}
                                >
                                    {design.name}
                                </div>
                            ))
                        }
                    </div>
                </div>
            );
        }
    };

    const renderPropertyDesigns = () => {
        if (data[PROPERTY_APPEARANCE_TYPE].length === 0) {
            return null;
        } else if (data[PROPERTY_APPEARANCE_TYPE].length > 0) {
            /* PROPERTY DESIGNS */
            return (
                <div className="w-full grid grid-cols-2 mt-5">
                    <div className="col-span-2 font-bold mb-4">
                        {t("{{capitalize, capitalize}}", { capitalize: t("property-designs") })}
                    </div>

                    <div className="border-b border-gray-200 font-bold pb-2 mb-3">
                        {t("{{capitalize, capitalize}}", { capitalize: t("property") })}
                    </div>
                    <div className="border-b border-gray-200 font-bold pb-2 mb-3">
                        {t("{{capitalize, capitalize}}", { capitalize: t("designs") })}
                    </div>

                    {
                        // eslint-disable-next-line
                        data[PROPERTY_APPEARANCE_TYPE].map((property) => (
                            <Fragment key={property.name}>
                                <div>{property.name}</div>
                                <div>
                                    {property.designs.map((design) => {
                                        return (
                                            <div
                                                key={design.ID}
                                                className={`break-all mb-2 ${
                                                    allowLink ? "text-blue-300 cursor-pointer" : ""
                                                }`}
                                                onClick={() => navigateToDesign(design.ID)}
                                            >
                                                {design.name}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="col-span-2 border-b border-gray-200 mt-2 mb-3"></div>
                            </Fragment>
                        ))
                    }
                </div>
            );
        }
    };

    return (
        <div className="w-full h-96 overflow-y-auto">
            {renderGlobalDesigns()}
            {renderPropertyDesigns()}
        </div>
    );
};

export default UseModalDesignsTree;
