import React, { useState, useEffect, useRef, useLayoutEffect, createRef, forwardRef } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate as useHistory } from "react-router-dom";
import classNames from "classnames";

import { normalizeToSearch } from "hooks/Utils/Utils";
import { useLoginProject, useLoginCorporate } from "hooks/Data/useUser";
import { useAuth } from "hooks/Session/auth";

import Search from "components/TextInput/Search";
import Button from "components/Button";
import Icon from "components/Icon";
import ProjectName from "components/ProjectName";
import ZafiroLogo from "components/ZafiroLogo";
import UserMenu from "components/UserMenu";
import UseProjectPagination from "./useProjectPagination";

const PROJECTS_PER_PAGE = 12;
const MINIMUM_PROJECTS_TO_SEARCH = 12;

const SelectProject = () => {
    const backButtonRef = useRef(null);

    const { t } = useTranslation();
    const history = useHistory();

    const { lang, user, loginProject, chain, projects } = useAuth();

    const [search, setSearch] = useState("");

    const loginParams = {
        chainID: chain?.id,
        chainRef: chain?.ref,
        lang,
        onSuccess: (data) => {
            if (data?.token) {
                loginProject(data?.projectRef, data?.token);
            }
        },
        onError: (error) => {
            toast.error(error);
        },
    };

    const { login } = useLoginProject(loginParams);
    const { login: loginCorporate } = useLoginCorporate(loginParams);

    const [projectsToDisplay, setProjectsToDisplay] = useState(projects);

    const [page, setPage] = useState(1);

    const showSearch = projects?.length >= MINIMUM_PROJECTS_TO_SEARCH;

    useEffect(() => {
        const result = searchProjects(projects, search);
        setProjectsToDisplay(result?.slice((page - 1) * PROJECTS_PER_PAGE, page * PROJECTS_PER_PAGE));
    }, [projects, search, page]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e?.code === "Space") {
                e.preventDefault();
                if (backButtonRef?.current) {
                    backButtonRef.current.click();
                }
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <>
            <div className="absolute top-0 left-0 right-0 flex items-center justify-between p-5">
                <div>
                    {user?.superUser ? (
                        <Button
                            ref={backButtonRef}
                            id="back"
                            design="link"
                            className="space-x-2"
                            onClick={() => history("/select-profile")}
                        >
                            <Icon type="chevron-left" size="xl" className="text-gray-600" />
                            <div className="text-xl font-bold">{t("back")}</div>
                        </Button>
                    ) : null}
                </div>
                <div className="flex flex-row space-x-5">
                    <UserMenu />
                </div>
            </div>

            <div className="bg-white flex items-start pt-28 pb-10 justify-center min-h-screen">
                <div className="flex flex-col space-y-6 justify-center text-gray-900 text-center">
                    <ZafiroLogo />
                    {projects?.length ? (
                        <>
                            <h2 className="text-2xl font-bold px-5">{t("select-hotel")}</h2>
                            {showSearch ? (
                                <div className="w-104 mx-auto">
                                    <Search
                                        id="project-search-input"
                                        placeholder={t("search-property") + "..."}
                                        onChange={setSearch}
                                        className="w-full"
                                    />
                                </div>
                            ) : null}
                            {projectsToDisplay?.length ? (
                                <>
                                    <ProjectsList
                                        projects={projectsToDisplay}
                                        onClick={(project) => {
                                            const isCorporate = project?.ref === "CORPORATE";
                                            if (isCorporate) {
                                                loginCorporate({ userRef: user?.ref });
                                            } else {
                                                login({ projectRef: project?.ref, userRef: user?.ref });
                                            }
                                        }}
                                    />
                                    <UseProjectPagination
                                        total={projects?.length}
                                        perPage={PROJECTS_PER_PAGE}
                                        page={page}
                                        setPage={setPage}
                                    />
                                </>
                            ) : (
                                <div className="text-gray-700">{t("no-results-found")}</div>
                            )}
                        </>
                    ) : (
                        <h2 className="text-2xl font-bold pt-5 px-5">{t("No projects available")}</h2>
                    )}
                </div>
            </div>
        </>
    );
};

export const searchProjects = (projects, search) => {
    return projects?.filter((project) => {
        if (!search?.length) {
            return true;
        }
        return normalizeToSearch(`${project?.name} ${project?.location}`).includes(normalizeToSearch(search));
    });
};

export const isCurrentPageProject = (displayPage, index) => {
    //Returns true if project is on current page
    let res;
    if (index + 1 <= displayPage * PROJECTS_PER_PAGE && index + 1 > (displayPage - 1) * PROJECTS_PER_PAGE) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const ProjectsList = ({ projects, onClick }) => {
    const [index, setIndex] = useState(null);
    const [buttons, setButtons] = useState(null);

    useEffect(() => {
        setButtons(
            projects?.length
                ? projects.map((project, index) => {
                      return (
                          <ProjectButton
                              ref={createRef()}
                              key={project?.ref}
                              project={project}
                              index={index}
                              onClick={() => {
                                  if (onClick) {
                                      onClick(project);
                                  }
                              }}
                          />
                      );
                  })
                : null
        );

        // Navigate through options with keyboard arrows
        const handleKeyDown = (e) => {
            if (projects?.length) {
                if (String(e.key).startsWith("Arrow")) {
                    e.preventDefault();
                    setIndex((index) => {
                        let newIndex = index || 0;
                        if (e.key === "ArrowRight" || e.key === "ArrowDown") {
                            newIndex++;
                        } else if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
                            newIndex--;
                        }
                        return index === null ? 0 : Math.max(0, Math.min(newIndex, projects.length - 1));
                    });
                }
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [projects]);

    useLayoutEffect(() => {
        if (index !== null) {
            const focused = buttons?.[index]?.ref;
            if (focused?.current) {
                focused.current.focus();
            }
        }
    }, [index]);

    if (!buttons?.length) {
        return null;
    }

    return (
        <div
            className={classNames({
                "grid gap-4 px-10": true,

                "lg:grid-cols-2": projects?.length > 3,
                "xl:grid-cols-3": projects?.length > 4,
            })}
        >
            {buttons}
        </div>
    );
};

export const ProjectButton = forwardRef(({ project, index, onClick }, ref) => {
    const itemClass = classNames({
        "flex p-5 h-24 min-w-104 items-center text-left rounded font-base": true,
        "border font-bold border-blue-400 text-blue-400 bg-white hover:bg-gray-200 focus:bg-gray-200": project?.global,
        "bg-blue-400 text-white hover:bg-blue-200 focus:bg-blue-200": !project?.global,
    });

    const name = project?.name;
    const hasChainModule = project?.hasChainModule ? "chain" : null;
    const description =
        project?.location && !project?.global ? (
            <span className="flex items-center space-x-2">
                <Icon type="location" size="sm" />
                <div>{project.location}</div>
            </span>
        ) : (
            project?.location
        );

    return (
        <Button
            ref={ref}
            key={project?.ref}
            id={project?.global ? "project-global" : `project-${index}`}
            className={itemClass}
            onClick={onClick}
        >
            <div className="flex items-start w-full">
                {hasChainModule ? (
                    <div className="py-1 px-3">
                        <ProjectName hasChainModule={hasChainModule} size="lg" />
                    </div>
                ) : null}
                <div>
                    <div className="text-2xl text-left">{name}</div>
                    {description ? <div className="text-base">{description}</div> : null}
                </div>
            </div>
        </Button>
    );
});

export default SelectProject;
