import React, { useEffect, useState, useContext } from "react";
import { useNavigate as useHistory, useLocation } from "react-router-dom";

import { Settings } from "sitemap";
import { UseMainMenu, UseSettingsMenu } from "hooks/map";

import NotFound from "components/Section/NotFound";

import { GlobalContext } from "contexts/Global";

// This component is used to redirect to the first section of the main menu
const Home = () => {
    const { paths, isLogged, isChainLogged, isProjectLogged, isProjectReady, permissions } = useContext(GlobalContext);
    const sections = UseMainMenu({ paths });
    const settings = UseSettingsMenu({ paths });

    const history = useHistory();
    const location = useLocation();

    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        let path = null;
        if (!isLogged) {
            path = "/login";
        } else if (!isChainLogged) {
            path = "/select-profile";
        } else if (!isProjectLogged) {
            path = "/select-project";
        } else if (location?.pathname === "/") {
            const firstSection = sections?.[0];
            if (firstSection) {
                path = `/${firstSection}`;
            } else {
                const firstSetting = settings?.[0];
                if (firstSetting) {
                    path = Settings?.[firstSetting]?.to;
                }
            }
        }
        if (path) {
            setRedirect(path);
        }
    }, [sections, paths, location?.pathname]);

    useEffect(() => {
        if ((!isProjectLogged || permissions) && redirect) {
            setRedirect(null);
            history(redirect);
        }
    }, [redirect, permissions, isProjectReady, permissions?.hotel?.map]);

    if (isProjectLogged && !redirect) {
        return <NotFound />;
    }

    return null;
};

export default Home;
