import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import SpecialTags from "./WidgetToolbars/OptionsDetails/SpecialTags";
import CustomFontSizes from "./WidgetToolbars/OptionsDetails/CustomFontSizes";
import TextEditorColorPicker from "./WidgetToolbars/OptionsDetails/TextEditorColorPicker";
import RdwReviewTranslations from "./WidgetToolbars/OptionsDetails/RdwReviewTranslations";

import { vw2px, px2vw, GetThemeDefault } from "../../../../hooks/Utils/DesignUtils";
import { setExecuteQuery } from "../../../../actions/sectionActions";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import { getFontSizes } from "../../../../hooks/Utils/Design/FontUtils";
import { useTranslation } from "react-i18next";
import CustomFontStyletype from "./WidgetToolbars/OptionsDetails/CustomFontStyletype";
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";
import { DEVICE } from "constants/editor";

const WidgetText = ({ isOnDrag, isOnResize }) => {
    const { t } = useTranslation();
    const { deviceType, editorSize, itemSelected, theme, screenType } = useContext(EditorContext);
    const { config, type, id: widgetID } = useContext(WidgetContext);

    const { fonts } = useSelector((state) => state.ui);
    const { executeQuery } = useSelector((state) => state.sectionContent);

    const { lang } = useParams();
    const dispatch = useDispatch();
    const EditorRef = useRef();

    const size = (s) => vw2px(s, editorSize?.width, deviceType === DEVICE.TYPE.MOBILE ? 3 : 1);
    const unsize = (s) => px2vw(s, editorSize ? editorSize.width : null, deviceType === "Mobile" ? 3 : 1);

    const pxHtml = (html) => {
        return (html + "").replace(/font-size:\s*(\d+|[.\d+]+)(\s*vw;)/g, function (match, number) {
            return "font-size:" + size(number) + "px;";
        });
    };
    const vwHtml = (html) => {
        return (html + "").replace(/font-size:\s*(\d+|[.\d+]+)(\s*px;)/g, function (match, number) {
            return "font-size:" + unsize(number) + "vw;";
        });
    };

    const parseHtmlFonts = (html) => {
        let fontsUsed = [];
        let found = html.indexOf("font-family");
        if (found > -1) {
            let newHtml = html.slice(found, -1);
            while (found > -1) {
                const fontName = newHtml.slice(0, newHtml.indexOf(";")).split(":")[1].trim();
                if (!fontsUsed.includes(fontName)) {
                    fontsUsed.push(fontName);
                }
                newHtml = newHtml.slice(newHtml.indexOf(";"), -1);
                found = newHtml.indexOf("font-family");
                newHtml = newHtml.slice(found, -1);
            }
        }
        let retunrDataFonts = [];

        if (fontsUsed.length > 0) {
            retunrDataFonts = fontsUsed.map((_fontUsed) => {
                const refFont = fonts.fontList.find((font) => font.name === _fontUsed);
                if (!refFont) return "";

                if (refFont && refFont.ref === "EXTERNAL") {
                    return `data-font='{"name":"${_fontUsed}", "externalUrl": "${refFont.externalUrl}"}'`;
                } else {
                    return `data-font='{"name":"${_fontUsed}", "libraryRef": "${refFont.ref}"}'`;
                }
            });
        }
        return retunrDataFonts.join(" ");
    };

    const addBase = (html) => {
        const datafonts = parseHtmlFonts(html);

        return `<div class="t3xtb453" style="font-size: ${unsize(
            defaultTextSize
        )}vw; font-family: ${defaultFontName}" ${datafonts}>${html}</div>`;
    };

    const removeBase = (html) => {
        const regex = /^<div class="t3xtb453"[^>]+>(.*)<\/div>$/ms;
        const result = html.match(regex);
        return result && result.length > 1 ? result[1] : html;
    };

    const { gridItems, widgetToolbarVisible: toolbar } = useSelector((state) => state.design);

    const defaultBgColor = GetThemeDefault({ theme, id: "bgColor" });
    const defaultFgColor = GetThemeDefault({ theme, id: "fgColor" });

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = config?.style || Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const bgColor = style?.bgColor || defaultBgColor;
    const fgColor = defaultFgColor;
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);

    const isSelected = itemSelected === widgetID;
    const settingsOpen = isSelected && toolbar === "text-settings";

    const html = removeBase(
        pxHtml(
            data && data.text
                ? data.text[lang]
                    ? data.text[lang]
                    : data.text && data.text.text
                    ? data.text.text
                    : ""
                : ""
        )
    );
    const defaultTextSize = size(theme?.paragraphStyle?.size);
    const defaultFontName = theme?.paragraphStyle?.font;
    const paragraphStyle = {
        fontFamily: defaultFontName,
        fontSize: defaultTextSize,
        bold: theme?.paragraphStyle?.bold,
        italic: theme?.paragraphStyle?.italic,
        underline: theme?.paragraphStyle?.underline,
    };
    const headerStyle = {
        fontFamily: theme?.headerStyle?.font,
        fontSize: size(theme?.headerStyle?.size),
        bold: theme?.headerStyle?.bold,
        italic: theme?.headerStyle?.italic,
        underline: theme?.headerStyle?.underline,
    };

    const [savedPx, setSavedPx] = useState(html);
    const [prevSelected, setPrevSelected] = useState(false);
    const [edited, setEdited] = useState(false);
    const [cEditorState, setCEditorState] = useState(getStateFromHtml(html));
    const [isEmpty, setIsEmpty] = useState(false);

    useEffect(() => {
        const getTextWidget = cEditorState.getCurrentContent().getPlainText().trim();
        setIsEmpty(getTextWidget === "");
    }, [cEditorState]);

    useEffect(() => {
        let timer;
        if (widget && widget.data && widget.data.text && !executeQuery) {
            timer = setTimeout(() => {
                forceTextUpdate();
            }, 200);
        }
        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line
    }, [widget]);

    useEffect(() => {
        const zone = document.querySelector(`#widget_zone_${widgetID}`);
        if (zone) {
            const value = html2plain(html);
            zone.style.backgroundColor = "transparent";
            zone.style.outline = value ? "" : "1px dashed #ccc";
        }
        return () => {};
    }, [widgetID, html]);

    useEffect(() => {
        const zone = document.querySelector(`#widget_zone_${widgetID}`);
        if (zone) {
            zone.style.backgroundColor = "transparent";
        }
        // eslint-disable-next-line
    }, [style]);

    useEffect(() => {
        const newState = getStateFromHtml(html);
        if (newState) {
            setCEditorState(newState);
        }
        return () => {};
        // eslint-disable-next-line
    }, [editorSize]);

    useEffect(() => {
        if (itemSelected === widgetID) {
            setPrevSelected(true);
            if (EditorRef && EditorRef.current) {
                EditorRef.current.focus();
                setCEditorState(EditorState.moveFocusToEnd(cEditorState));
            }
        } else if (prevSelected) {
            setPrevSelected(false);
            forceTextUpdate();
        }
        // eslint-disable-next-line
    }, [itemSelected, widgetID]);

    const html2plain = (code) => {
        return (code + "")
            .replace(/<[^>]+>/g, "")
            .replace("&nbsp;", "")
            .replace(" ", "")
            .replace("\n", "");
    };

    const forceTextUpdate = () => {
        setEdited(true);
        const newHtml = getHtmlFromState(cEditorState);
        if (newHtml === savedPx) return false;
        setSavedPx(newHtml);
        const data = {
            text: {
                [lang]: addBase(vwHtml(newHtml)),
            },
        };
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, value: data })));
    };

    return (
        <div
            className="unselectable h-full w-full"
            style={{
                fontSize: `${size(1)}px`,
                backgroundColor: bgColor,
                color: fgColor,
                padding: style && style.padding ? `${style.padding}px` : "",
                borderRadius: style && style.radius ? `${style.radius}rem` : "",
                overflowWrap: "break-word",
            }}
            onDoubleClick={(e) => e.stopPropagation()}
            onPointerLeave={(e) => {
                if (cEditorState && isSelected) {
                    forceTextUpdate();
                }
            }}
        >
            {(isSelected && (isOnDrag === true || isOnResize === true) && isEmpty === true) ||
            (!isSelected && isEmpty === true) ? (
                <EmptyWidget radius={style ? style.radius : 0} type={type} />
            ) : !isSelected && !edited ? (
                <div
                    className="unselectable"
                    style={{ fontSize: `${defaultTextSize}px`, fontFamily: defaultFontName }}
                    dangerouslySetInnerHTML={{ __html: html }}
                ></div>
            ) : (
                <div className="h-full overflow-hidden">
                    <Editor
                        editorRef={(ref) => (EditorRef.current = ref)}
                        toolbarStyle={{
                            position: "fixed",
                            width: "31rem",
                            padding: "1rem",
                            zIndex: 9999,
                            borderBottom: "1px solid #E9EDF0",
                            borderRadius: ".5rem",
                            boxShadow: "0 0 4px rgba(0,0,0,.1)",
                            background: "white",
                            display: "grid",
                            gridTemplateColumns: "repeat(7,1fr)",
                            gridTemplateRows: "auto",
                        }}
                        readOnly={!isSelected}
                        toolbarHidden={!settingsOpen}
                        editorState={cEditorState}
                        onEditorStateChange={setCEditorState}
                        editorStyle={{
                            fontSize: `${defaultTextSize}px`,
                            fontFamily: defaultFontName,
                            color: defaultFgColor,
                            fontWeight: theme?.paragraphStyle?.bold ? 500 : 400,
                            fontStyle: theme?.paragraphStyle?.italic ? "italic" : "normal",
                            textDecoration: theme?.paragraphStyle?.underline ? "underline" : "none",
                            minWidth: "100%",
                            // wordBreak: "break-all",
                        }}
                        stripPastedStyles={true}
                        toolbarCustomButtons={[
                            <CustomFontSizes
                                defaultSize={defaultTextSize}
                                toolbar={{
                                    options: ["sizes"],

                                    sizes: getFontSizes(editorSize.width, deviceType),
                                }}
                                edirotRef={EditorRef}
                            />,

                            // <Select
                            //     title={t("styletype")}
                            //     id="styletype"
                            //     // deviceSpecific={true}
                            //     adjust="w-full mr-2"
                            //     data={{
                            //         selected:
                            //             widget.widgetStyle && widget.widgetStyle.styletype
                            //                 ? widget.widgetStyle.styletype
                            //                 : "paragraph",
                            //         options: [
                            //             { value: "header", text: t("heading") },
                            //             { value: "paragraph", text: t("paragraph") },
                            //         ],
                            //     }}
                            // />,
                            <CustomFontStyletype
                                toolbar={{
                                    styles: [
                                        { value: "header", text: t("heading"), style: headerStyle },
                                        { value: "paragraph", text: t("paragraph"), style: paragraphStyle },
                                    ],
                                }}
                                edirotRef={EditorRef}
                            />,
                            <TextEditorColorPicker defaultColor={defaultFgColor} />,
                            <SpecialTags screenType={screenType} deviceType={deviceType} />,
                            <RdwReviewTranslations />,
                        ]}
                        toolbar={{
                            options: ["inline", "list", "textAlign", "fontFamily", "history", "remove"],
                            inline: {
                                options: ["bold", "italic", "underline", "strikethrough"],
                            },
                            fontFamily: {
                                options: fonts?.fontNames,
                            },
                            list: { options: ["unordered"] },
                            textAlign: {
                                inDropdown: false,
                                options: ["left", "center", "right", "justify"],
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
};

const getStateFromHtml = (html) => {
    const contentBlock = htmlToDraft(html);
    const contentState = contentBlock ? ContentState.createFromBlockArray(contentBlock.contentBlocks) : null;
    return contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty();
};

const getHtmlFromState = (state) => {
    const html1 = draftToHtml(convertToRaw(state.getCurrentContent()));
    const emptyParagraph = /(<p[^>]*>)\s*(<\/p>)/gm;
    const element = /<(p|li|ul|div)>/gm;
    const styledElement = /(<(p|li|ul|div) style=")([^"]*)"([^>]*>)/gm;
    const list = /<ul[^>]*>/gm;
    const listElement = /<li[^>]*>/gm;
    let html2 = html1
        .replace(emptyParagraph, "$1&nbsp;$2")
        .replace(element, `<$1 style="white-space:pre-wrap;">`)
        .replace(styledElement, `$1white-space:pre-wrap;$3"$4`)
        .replace(list, `<ul style="margin:16px 0;padding:0">`)
        .replace(listElement, `<li style="list-style-type:disc;margin-left:1.5em">`);
    return html2;
};

export default WidgetText;
