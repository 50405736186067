import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useSelector, useDispatch } from "react-redux";

import routes from "routes";
import { Session } from "hooks/Utils/Session";
import ComposePermissionsTree from "hooks/Utils/ComposePermissionsTree";
import { setPermissions as setReduxPermissions } from "actions/uiActions";

import useProjectAuth from "./auth.project";
import useChainAuth from "./auth.chain";
import useUserAuth from "./auth.user";

export const useAuthentication = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onError = () => {
        return (err) => {
            console.error(err);
            toast.error(t("mutation-error"));
        };
    };

    const user = useUserAuth({ onError });
    const chain = useChainAuth({ onError });
    const project = useProjectAuth({ user, onError });

    const reduxPermissions = useSelector((state) => state.ui?.permissions);
    const storedPaths = Session.getPaths();

    const [availablePaths, setAvailablePaths] = useState(storedPaths);

    const loading = user.loading || chain.loading || project.loading;
    const updateLanguage = (lang) => {
        user.updateLanguage(lang);
        chain.updateLanguage(lang);
        project.updateLanguage(lang);
    };

    const [permissions, setPermissions] = useState(reduxPermissions);

    const token = project.token || chain.token || user.token;

    const isSuperUser = user.info?.superUser;
    const isCorporate = project.info?.ref === "CORPORATE";
    const hasCorporate = user.info?.corporate;
    const userManagerRole = user.info?.role;
    const isESChain = Session.isESChain(chain?.info?.id);
    const hasChainModule = project.info?.hasChainModule;

    // Check if the project is Zafiro V3 (no one of the following permissions: productTV, productMOBILE, productCAST, productWIFI)
    const isZafiroV3 =
        project?.info?.ref &&
        project.info.ref !== "CORPORATE" &&
        project.permissions &&
        !project.permissions.some((p) => ["productTV", "productMOBILE", "productCAST", "productWIFI"].includes(p));

    useEffect(() => {
        Session.setPaths(availablePaths);
    }, [availablePaths]);

    useEffect(() => {
        if (project?.ready && permissions !== undefined) {
            if (isZafiroV3) {
                setAvailablePaths([]);
            } else {
                const checkEnabled = (route) => {
                    return isSectionEnabled({
                        route,
                        user: user?.info,
                        chain: chain?.info,
                        project: project?.info,
                        isDhcp: project?.dhcp,
                        permissions: permissions,
                        hasCorporate,
                    });
                };

                const enabledRoutes = routes?.length
                    ? routes
                          .map((route) => {
                              if (checkEnabled(route)) {
                                  return route;
                              }
                              return null;
                          })
                          ?.filter((route) => route)
                    : [];
                if (enabledRoutes?.length) {
                    enabledRoutes.forEach((route) => {
                        //check if redirect point to an enabled section
                        //if not, redirect to the first enabled section of the same package
                        if (route.redirect && !enabledRoutes.find((route) => route.path === route.redirect)) {
                            if (route.sectionID) {
                                const itemRoutes = {
                                    ...enabledRoutes?.filter(
                                        (r) =>
                                            r.sectionID === route.sectionID &&
                                            r.path !== route.path &&
                                            !r.path.includes(":")
                                    ),
                                };
                                if (itemRoutes?.length) {
                                    const first = getPackageFirstEnabled(itemRoutes, checkEnabled);
                                    if (first) {
                                        route.redirect = first;
                                    }
                                }
                            }
                        }
                    });
                }

                if (chain.projects) {
                    chain.projects.map((p) => {
                        if (parseInt(p.id) === parseInt(project.info?.id)) {
                            p.lastSelectedWithMinimalPermissions = true;
                        } else {
                            p.lastSelectedWithMinimalPermissions = false;
                        }
                    });
                }

                const newAvailablePaths = enabledRoutes ? enabledRoutes.map((route) => route?.path) : [];
                setAvailablePaths(newAvailablePaths);
            }
        }
    }, [
        permissions,
        user?.info,
        chain?.info,
        project?.info,
        project?.dhcp,
        routes,
        chain?.projects,
        isZafiroV3,
        chain?.projects,
    ]);

    useEffect(() => {
        if (!isZafiroV3 && project?.ready) {
            setPermissions(
                ComposePermissionsTree({
                    globalPerms: isCorporate ? project.permissions : null,
                    chainPerms: isCorporate ? project.chainPermissions : null,
                    projectPermissions: !isCorporate ? project.permissions : null,
                    userAccesses: project.accesses,
                    userManagerRole,
                    isSuperUser,
                    isCorporate,
                    isESChain,
                    hasChainModule,
                })
            );
        } else {
            setPermissions(undefined);
        }
    }, [
        isCorporate,
        project?.ready,
        project?.chainPermissions,
        project?.permissions,
        project?.accesses,
        userManagerRole,
        isSuperUser,
        hasCorporate,
        isZafiroV3,
    ]);

    useEffect(() => {
        if (JSON.stringify(permissions) !== JSON.stringify(reduxPermissions)) {
            dispatch(setReduxPermissions(permissions));
        }
    }, [permissions]);

    return {
        token,
        permissions,
        languages: project?.languages,
        defaultLanguage: project?.defaultLanguage,
        updateLanguage,
        paths: availablePaths,
        loading,
        user,
        chain,
        project: project
            ? {
                  ...project,
                  login: (data, token) => {
                      setPermissions(undefined);
                      setAvailablePaths(undefined);
                      project.login(data, token);
                  },
              }
            : null,
        isZafiroV3,
    };
};

export const useTokenLanguage = ({ onError }) => {
    const [update, { data, loading, error }] = useMutation(
        gql`
            mutation changeUserLang($lang: String!) {
                changeUserLang(lang: $lang) {
                    ok
                    token
                }
            }
        `,
        { onError }
    );
    return {
        update: ({ lang, token }) => {
            if (token) {
                update({ variables: { lang }, context: { headers: { Authorization: token } } });
            }
        },
        token: data?.changeUserLang?.token || null,
        success: data?.changeUserLang?.ok,
        error,
        loading,
    };
};

const getPackageFirstEnabled = (enabledRoutes, checkEnabled) => {
    const found = enabledRoutes?.length ? enabledRoutes.find((route) => checkEnabled(route)) : null;
    if (found) {
        return found.redirect
            ? getPackageFirstEnabled(
                  enabledRoutes?.filter((route) => route.redirect !== found.redirect),
                  checkEnabled
              )
            : found.path;
    }
    return null;
};

const isSectionEnabled = (props) => {
    const { user, chain, project, isDhcp, hasCorporate, route, permissions } = props || {};
    const isSuperUser = user?.superUser;
    const userManagerRole = user?.role;
    const isCorporate = project?.ref === "CORPORATE";
    const isChainLogged = !!chain?.ref;

    let enabled = route?.check
        ? route.check({
              isChainLogged,
              user,
              chain,
              project,
              permissions,
              isCorporate,
              hasCorporate,
              userManagerRole,
              isDhcp,
              isSuperUser,
          })
        : !!route;

    if (enabled && route?.redirect && !route?.initial) {
        const foundRedirect = routes?.find((r) => r?.path === route?.redirect);
        if (foundRedirect) {
            enabled = isSectionEnabled({ ...props, route: foundRedirect });
        }
    }

    return enabled;
};
